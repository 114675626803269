import React from "react";

import styles from "./Landing.module.scss";
import Promo from "./components/Promo/Promo";
import Explore from "./components/Explore/Explore";
import Rooms from "./components/Rooms/Rooms";
import Roadmap from "./components/Roadmap/Roadmap";
import Join from "./components/Join/Join";
import Faq from "../QuestPage/components/Faq/Faq";
import Footer from "../Footer/Footer";

function Landing() {
  return (
    <div className={styles.landing}>
      <Promo />
      <Explore />
      <Rooms />
      <Roadmap />
      <Join />
      <Faq title={"Got Questions?"} />
      <Footer />
    </div>
  );
}

export default Landing;
