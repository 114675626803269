import React from "react";

import styles from "./QuestLevel.module.scss";
import GemIcon from "../../../../img/gem.svg";
import LockIcon from "../../../../img/lock.svg";

function QuestLevel({ status, coins }) {
  return (
    <div className={styles.status}>
      {status === "unlocked" ? (
        <>
          <div className={styles.status__unlocked}>Unlocked</div>
          <div className={styles.status__wrapper}>
            <div className={styles.status__unlocked_text}>
              Earn up to {coins}
            </div>
            <img src={GemIcon} alt="GemIcon" />
          </div>
        </>
      ) : (
        <div className={styles.status__wrapper}>
          <img src={LockIcon} alt="LockIcon" />
          <div className={styles.status__locked}>
            Unlocks at {coins} <img src={GemIcon} alt="GemIcon" />
          </div>
        </div>
      )}
    </div>
  );
}

export default QuestLevel;
