import React, { useState } from "react";

import styles from "./Faq.module.scss";
import FaqRow from "../FaqRow/FaqRow";
import FaqIcon from "../../../../img/faqBg.svg";

const faqData = [
  {
    question: "Compound TRON dogecoin helium polygon dai enjin THETA XRP?",
    text: "Solana polkadot aave gala WAX eCash TRON amp dogecoin polymath. USD audius filecoin kadena vechain serum THETA solana loopring litecoin. ICON quant polymath avalanche cardano kusama kadena ipsum kusama velas. EOS BitTorrent telcoin WAX celo dai zcash. Kusama TRON decred siacoin serum.",
  },
  {
    question: "Polkadot helium helium flow stacks celo?",
    text: "Solana polkadot aave gala WAX eCash TRON amp dogecoin polymath. USD audius filecoin kadena vechain serum THETA solana loopring litecoin. ICON quant polymath avalanche cardano kusama kadena ipsum kusama velas. EOS BitTorrent telcoin WAX celo dai zcash. Kusama TRON decred siacoin serum.",
  },
  {
    question: "Monero neo serum uniswap kava?",
    text: "Solana polkadot aave gala WAX eCash TRON amp dogecoin polymath. USD audius filecoin kadena vechain serum THETA solana loopring litecoin. ICON quant polymath avalanche cardano kusama kadena ipsum kusama velas. EOS BitTorrent telcoin WAX celo dai zcash. Kusama TRON decred siacoin serum.",
  },
  {
    question: "Velas flow algorand litecoin dash shiba-inu?",
    text: "Solana polkadot aave gala WAX eCash TRON amp dogecoin polymath. USD audius filecoin kadena vechain serum THETA solana loopring litecoin. ICON quant polymath avalanche cardano kusama kadena ipsum kusama velas. EOS BitTorrent telcoin WAX celo dai zcash. Kusama TRON decred siacoin serum.",
  },
  {
    question: "Polkadot TRON tether holo horizen ox?",
    text: "Solana polkadot aave gala WAX eCash TRON amp dogecoin polymath. USD audius filecoin kadena vechain serum THETA solana loopring litecoin. ICON quant polymath avalanche cardano kusama kadena ipsum kusama velas. EOS BitTorrent telcoin WAX celo dai zcash. Kusama TRON decred siacoin serum.",
  },
  {
    question: "Amp monero helium chainlink USD fantom eCash waves enjin ox?",
    text: "Solana polkadot aave gala WAX eCash TRON amp dogecoin polymath. USD audius filecoin kadena vechain serum THETA solana loopring litecoin. ICON quant polymath avalanche cardano kusama kadena ipsum kusama velas. EOS BitTorrent telcoin WAX celo dai zcash. Kusama TRON decred siacoin serum.",
  },
  {
    question: "Celsius kadena filecoin arweave crypto chainlink terraUSD?",
    text: "Solana polkadot aave gala WAX eCash TRON amp dogecoin polymath. USD audius filecoin kadena vechain serum THETA solana loopring litecoin. ICON quant polymath avalanche cardano kusama kadena ipsum kusama velas. EOS BitTorrent telcoin WAX celo dai zcash. Kusama TRON decred siacoin serum.",
  },
];

function Faq({ title = "FAQ" }) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setMousePosition({ x: clientX, y: clientY });
  };

  const calcTransform = () => {
    const xOffset = (mousePosition.x / window.innerWidth) * 30;
    const yOffset = (mousePosition.y / window.innerHeight) * 30;
    return `translate(${xOffset}px, ${yOffset}px)`;
  };

  return (
    <div className={styles.faq} onMouseMove={handleMouseMove}>
      <div className="container">
        <div className={styles.faq__container}>
          <img
            className={styles.faq__bg}
            style={{ transform: calcTransform() }}
            src={FaqIcon}
            alt="bg"
          />
          <div className={styles.faq__title}>{title}</div>
          <div className={styles.faq__list}>
            {faqData?.map((item, index) => {
              return <FaqRow key={index} info={item} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
