import React from "react";

import styles from "./FeaturesStatus.module.scss";
import TickIcon from "../../../../img/featuresTick.svg";
import PlusIcon from "../../../../img/featuresPlus.svg";
import SettingIcon from "../../../../img/settings.svg";
import combineClasses from "../../../../helpers/combineClasses";

function FeaturesStatus({ text }) {
  return (
    <div
      className={combineClasses(
        styles,
        "status",
        text === "Comming Soon"
          ? "status__soon"
          : text === "In Progress"
          ? "status__progress"
          : "status__backlog"
      )}
    >
      {text === "Comming Soon" ? (
        <img src={TickIcon} alt="TickIcon" />
      ) : text === "In Progress" ? (
        <img src={PlusIcon} alt="PlusIcon" />
      ) : (
        <img src={SettingIcon} alt="SettingIcon" />
      )}
      <div>{text}</div>
    </div>
  );
}

export default FeaturesStatus;
