import React, { useEffect, useRef, useState } from "react";

import styles from "./Roadmap.module.scss";
import Status from "./components/Status/Status";
import TickIcon from "../../../../img/greenTick.svg";
import DotsIcon from "../../../../img/dots.svg";
import TickOrange from "../../../../img/orangeTick.svg";
import TickEmpty from "../../../../img/EmptyTick.svg";
import combineClasses from "../../../../helpers/combineClasses";
import RoadMapsDotsSmall from "../../../../img/roadmapDotsSmall.svg";
import RoadMapsDotsBig from "../../../../img/roadmapDotsBig.svg";

function Roadmap() {
  const dotsRefs = useRef([]); // Массив для всех блоков с точками
  const [visibleBlocks, setVisibleBlocks] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = Number(entry.target.getAttribute("data-index"));

        if (entry.isIntersecting) {
          setVisibleBlocks((prevVisibleBlocks) =>
            [...prevVisibleBlocks, index].filter(
              (value, idx, self) => self.indexOf(value) === idx
            )
          );
        }
      });
    });

    dotsRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      dotsRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const renderDots = (blockIndex, delay) =>
    visibleBlocks.includes(blockIndex) &&
    Array.from({ length: 7 }).map((_, index) => (
      <div
        key={index}
        className={styles.dot}
        style={{
          animationDelay: `${index * 0.1 + delay}s`,
          opacity: 0,
          transition: "opacity 0.5s ease",
        }}
      />
    ));

  return (
    <div className={styles.roadmap}>
      <div className="container">
        <div className={styles.roadmap__title}>Our Roadmap</div>
        <div className={styles.roadmap__status}>
          <div className="wow animate__animated animate__fadeIn">
            <Status text={"Q1 2024"} color={"green"} />
          </div>
          <div
            className={`wow ${styles.roadmap__dots}`}
            ref={(el) => (dotsRefs.current[0] = el)}
            data-index={0}
          >
            {renderDots(0, 0)}
          </div>
          <img
            className={styles.roadmap__dots_small}
            src={RoadMapsDotsSmall}
            alt="RoadMapsDotsSmall"
          />
          <div className={styles.roadmap__status_wrapper}>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
            >
              <img src={TickIcon} alt="TickIcon" />
              <div className={styles.roadmap__status_block_completed}>
                Velas celsius
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
            >
              <img src={TickIcon} alt="TickIcon" />
              <div className={styles.roadmap__status_block_completed}>
                Horizen ICON{" "}
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
            >
              <img src={TickIcon} alt="TickIcon" />
              <div className={styles.roadmap__status_block_completed}>
                TRON cosmos
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
            >
              <img src={TickIcon} alt="TickIcon" />
              <div className={styles.roadmap__status_block_completed}>
                Shiba-inu terraUSD
              </div>
            </div>
          </div>
        </div>
        <div
          className={combineClasses(
            styles,
            "roadmap__status",
            "roadmap__status_centred"
          )}
        >
          <div
            className="wow animate__animated animate__fadeIn"
            data-wow-delay="1s"
          >
            <Status text={"Q1 2024"} color={"green"} />
          </div>
          <div
            className={`${styles.roadmap__dots} ${styles.roadmap__dots_2}`}
            ref={(el) => (dotsRefs.current[1] = el)}
            data-index={1}
          >
            {renderDots(1, 1)}
          </div>
          <img
            className={styles.roadmap__dots_small}
            src={RoadMapsDotsSmall}
            alt="RoadMapsDotsSmall"
          />
          <div className={styles.roadmap__status_wrapper}>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.1s"
            >
              <img src={TickIcon} alt="TickIcon" />
              <div className={styles.roadmap__status_block_completed_more}>
                Tether revain
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.1s"
            >
              <img src={TickIcon} alt="TickIcon" />
              <div className={styles.roadmap__status_block_completed_more}>
                Cardano secret
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.1s"
            >
              <img src={TickIcon} alt="TickIcon" />
              <div className={styles.roadmap__status_block_completed_more}>
                Chiliz filecoin siacoin
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.1s"
            >
              <img src={TickIcon} alt="TickIcon" />
              <div className={styles.roadmap__status_block_completed_more}>
                Terra flow elrond
              </div>
            </div>
          </div>
        </div>
        <div
          className={combineClasses(
            styles,
            "roadmap__status",
            "roadmap__status_centred",
            "roadmap__status_yellow"
          )}
        >
          <div
            className="wow animate__animated animate__fadeIn"
            data-wow-delay="2s"
          >
            <Status text={"Q1 2024"} color={"yellow"} />
          </div>
          <div
            className={`${styles.roadmap__dots} ${styles.roadmap__dots_3}`}
            ref={(el) => (dotsRefs.current[2] = el)}
            data-index={2}
          >
            {renderDots(2, 2)}
          </div>
          <img
            className={styles.roadmap__dots_big}
            src={RoadMapsDotsBig}
            alt="RoadMapsDotsBig"
          />
          <div className={styles.roadmap__status_wrapper}>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.2s"
            >
              <img src={TickOrange} alt="TickOrange" />
              <div className={styles.roadmap__status_block_inprogress}>
                Decentraland crypto
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.2s"
            >
              <img src={TickOrange} alt="TickOrange" />
              <div className={styles.roadmap__status_block_inprogress}>
                Celo solana
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.2s"
            >
              <img src={TickOrange} alt="TickOrange" />
              <div className={styles.roadmap__status_block_inprogress}>
                THETA harmony
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.2s"
            >
              <img src={TickOrange} alt="TickOrange" />
              <div className={styles.roadmap__status_block_inprogress}>
                Dash bitcoin harmony
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.2s"
            >
              <img src={TickOrange} alt="TickOrange" />
              <div className={styles.roadmap__status_block_inprogress}>
                Fantom THETA arweave
              </div>
            </div>
          </div>
        </div>
        <div
          className={combineClasses(
            styles,
            "roadmap__status",
            "roadmap__status_centred"
          )}
        >
          <div
            className="wow animate__animated animate__fadeIn"
            data-wow-delay="3s"
          >
            <Status text={"Q1 2024"} color={"gray"} />
          </div>
          <div
            className={`${styles.roadmap__dots} ${styles.roadmap__dots_4}`}
            ref={(el) => (dotsRefs.current[3] = el)}
            data-index={3}
          >
            {renderDots(3, 3)}
          </div>
          <img
            className={styles.roadmap__dots_small}
            src={RoadMapsDotsSmall}
            alt="RoadMapsDotsSmall"
          />
          <div className={styles.roadmap__status_wrapper}>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.3s"
            >
              <img src={TickEmpty} alt="TickEmpty" />
              <div className={styles.roadmap__status_block_completed_more}>
                Ox helium audius
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.3s"
            >
              <img src={TickEmpty} alt="TickEmpty" />
              <div className={styles.roadmap__status_block_completed_more}>
                TRON celsius livepeer
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.3s"
            >
              <img src={TickEmpty} alt="TickEmpty" />
              <div className={styles.roadmap__status_block_completed_more}>
                Ipsum hive
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.3s"
            >
              <img src={TickEmpty} alt="TickEmpty" />
              <div className={styles.roadmap__status_block_completed_more}>
                Enjin hedera
              </div>
            </div>
          </div>
        </div>
        <div
          className={combineClasses(
            styles,
            "roadmap__status",
            "roadmap__status_ended"
          )}
        >
          <div
            className="wow animate__animated animate__fadeIn"
            data-wow-delay="4s"
          >
            <Status text={"Q1 2024"} color={"gray"} />
          </div>
          <div className={styles.roadmap__status_wrapper}>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.4s"
            >
              <img src={TickEmpty} alt="TickEmpty" />
              <div className={styles.roadmap__status_block_completed}>
                Dai ICON crypto
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.4s"
            >
              <img src={TickEmpty} alt="TickEmpty" />
              <div className={styles.roadmap__status_block_completed}>
                XRP zcash compound
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.4s"
            >
              <img src={TickEmpty} alt="TickEmpty" />
              <div className={styles.roadmap__status_block_completed}>
                Tezos stellar maker
              </div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn ${styles.roadmap__status_block}`}
              data-wow-delay="0.4s"
            >
              <img src={TickEmpty} alt="TickEmpty" />
              <div className={styles.roadmap__status_block_completed}>
                Compound chiliz
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
