import React from "react";

import styles from "./Status.module.scss";
import combineClasses from "../../../../../../helpers/combineClasses";

function Status({ text, color }) {
  return (
    <div
      className={combineClasses(
        styles,
        "status",
        color === "green"
          ? "completed"
          : color === "yellow"
          ? "inprogress"
          : "gray"
      )}
    >
      <div>{text}</div>
    </div>
  );
}

export default Status;
