import React from "react";
import Main from "./component/Main/Main";
import Footer from "../Footer/Footer";

// import styles from "./QuestPage.module.scss";

function CurrentQuest() {
  return (
    <div>
      <Main />
      <Footer />
    </div>
  );
}

export default CurrentQuest;
