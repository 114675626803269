import React from "react";

import styles from "./Footer.module.scss";
import LogoIcon from "../../img/logo.svg";
import TelegramIcon from "../../img/telegram.svg";
import TwitterIcon from "../../img/twitter.svg";
import DiscordIcon from "../../img/discord.svg";
import LinkedinIcon from "../../img/linkedin.svg";
import WebAppIcon from "../../img/webApp.svg";
import TelegramAppIcon from "../../img/telegramApp.svg";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className="container">
        <div className={styles.footer__container}>
          <img className={styles.footer__logo} src={LogoIcon} alt="LogoIcon" />
          <div className={styles.footer__wrapper}>
            <div className={styles.footer__description}>
              <div className={styles.footer__description_text}>
                Hero Guys is a play-to-earn game that combines battle royale,
                platformer, and arcade elements. Up to 60 players compete in
                elimination rounds, relying on quick reflexes, skill, and
                strategy to win.
              </div>
              <div className={styles.footer__description_links}>
                <div className={styles.footer__description_btn_wrapper}>
                  <div className={styles.footer__description_btn}>
                    <img src={TelegramIcon} alt="telegram" />
                  </div>
                </div>
                <div className={styles.footer__description_btn_wrapper}>
                  <div className={styles.footer__description_btn}>
                    <img src={TwitterIcon} alt="twitter" />
                  </div>
                </div>
                <div className={styles.footer__description_btn_wrapper}>
                  <div className={styles.footer__description_btn}>
                    <img src={DiscordIcon} alt="discord" />
                  </div>
                </div>
                <div className={styles.footer__description_btn_wrapper}>
                  <div className={styles.footer__description_btn}>
                    <img src={LinkedinIcon} alt="Linkedin" />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footer__links}>
              <div className={styles.footer__title}>Play Hero Guys</div>
              <div className={styles.footer__links_list}>
                {/* <img src={AppstoreIcon} alt="AppstoreIcon" /> */}
                <img src={WebAppIcon} alt="WebAppIcon" />
                {/* <img src={GooglePlayIcon} alt="GooglePlayIcon" /> */}
                <img src={TelegramAppIcon} alt="TelegramAppIcon" />
              </div>
            </div>
            <div className={styles.footer__blocks}>
              <div className={styles.footer__legal}>
                <div className={styles.footer__title}>Legal</div>
                <div className={styles.footer__legal_link}>Privacy Policy</div>
                <div className={styles.footer__legal_link}>
                  Terms of Service
                </div>
                <div className={styles.footer__legal_link}>Cookie Policy</div>
              </div>
              <div className={styles.footer__contact}>
                <div className={styles.footer__title}>Contact us</div>
                <div className={styles.footer__legal_link}>
                  Investment & Community Request
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer__copy}>
            © 2024 HeroGuys. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
