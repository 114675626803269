import React from "react";

import styles from "./Join.module.scss";
import CompleteIcon from "../../../../img/complete.svg";
import DiamondIcon from "../../../../img/diamond.svg";
import LeaderBoardIcon from "../../../../img/leaderboard.svg";
import UnlockIcon from "../../../../img/unlock.svg";

function Join() {
  return (
    <div className={styles.join}>
      <div className="container">
        <div className={styles.join__block}>
          <div className={styles.join__block_title}>
            Join Alpha Waitlist & Vibrant Community
          </div>
          <div className={styles.join__block_list}>
            <div className={`wow animate__animated animate__fadeIn`}>
              <div className={styles.join__block_list_image}>
                <img src={CompleteIcon} alt="CompleteIcon" />
              </div>
              <div>Complete Quests</div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn`}
              data-wow-delay="0.1s"
            >
              <div className={styles.join__block_list_image}>
                <img src={DiamondIcon} alt="DiamondIcon" />
              </div>
              <div>Earn Hero Points</div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn`}
              data-wow-delay="0.2s"
            >
              <div className={styles.join__block_list_image}>
                <img src={LeaderBoardIcon} alt="LeaderBoardIcon" />
              </div>
              <div>Progress in Leaderboard</div>
            </div>
            <div
              className={`wow animate__animated animate__fadeIn`}
              data-wow-delay="0.3s"
            >
              <div className={styles.join__block_list_image}>
                <img src={UnlockIcon} alt="UnlockIcon" />
              </div>
              <div>Unlock content & rewards</div>
            </div>
          </div>
          <div className={styles.join__btn_wrapper}>
            <div className={styles.join__btn}>Go To Waitlist</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Join;
