import React, { useEffect, useRef, useState } from "react";

import styles from "./QuestFloatingBlock.module.scss";
import CompleteIcon from "../../../../img/complete.svg";
import DiamondIcon from "../../../../img/diamond.svg";
import LeaderBoardIcon from "../../../../img/leaderboard.svg";
import UnlockIcon from "../../../../img/unlock.svg";
import LogoutIcon from "../../../../img/logout.svg";
import EditIcon from "../../../../img/edit.svg";
import GemIcon from "../../../../img/gem.svg";
import LockIcon from "../../../../img/lock.svg";
import ProgressBar from "../ProgressBar/ProgressBar";
import combineClasses from "../../../../helpers/combineClasses";

function QuestFloatingBlock({ isRegistered }) {
  const elementRef = useRef(null);

  const [isShowBlock, setIsShowBlock] = useState(false);

  const scrollToTarget = () => {
    const targetElement = document.getElementById("leaderboard");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setIsShowBlock(true);
        } else {
          setIsShowBlock(false);
        }
      },
      { threshold: 0 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <>
      {isShowBlock ? (
        <div className={styles.block__mobile}>
          <div>
            <div className={styles.block__mobile_title}>Join waitlist</div>
            <div className={styles.block__mobile_description}>
              Complete Quests, Earn Points, Progress, Unlock Content & Rewards
            </div>
          </div>
          <div className={styles.block__btn_wrapper}>
            <div className={styles.block__btn}>Join with Email</div>
          </div>
        </div>
      ) : null}
      <div
        className={combineClasses(
          styles,
          "block",
          !isRegistered ? "block__registered" : ""
        )}
        ref={elementRef}
      >
        {isRegistered ? (
          <>
            <div className={styles.block__title}>Join waitlist</div>
            <div className={styles.block__description}>
              How to get to beta and win rewards:
            </div>
            <div className={styles.block__list}>
              <div className={styles.block__list_row}>
                <div className={styles.block__list_row_icon}>
                  <img src={CompleteIcon} alt="CompleteIcon" />
                </div>
                <div className={styles.block__list_row_text}>
                  Complete Quests
                </div>
              </div>
              <div className={styles.block__list_row}>
                <div className={styles.block__list_row_icon}>
                  <img src={DiamondIcon} alt="CompleteIcon" />
                </div>
                <div className={styles.block__list_row_text}>
                  Earn Hero Points
                </div>
              </div>
              <div className={styles.block__list_row}>
                <div className={styles.block__list_row_icon}>
                  <img src={LeaderBoardIcon} alt="CompleteIcon" />
                </div>
                <div className={styles.block__list_row_text}>
                  Progress in Leaderboard
                </div>
              </div>
              <div className={styles.block__list_row}>
                <div className={styles.block__list_row_icon}>
                  <img src={UnlockIcon} alt="CompleteIcon" />
                </div>
                <div className={styles.block__list_row_text}>
                  Unlock content & rewards
                </div>
              </div>
            </div>
            <div className={styles.block__btn_wrapper}>
              <div className={styles.block__btn}>Join with Email</div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.registered__title}>
              You're on the waiting list!
            </div>
            <div className={styles.registered__info}>
              <div className={styles.registered__row}>
                <div className={styles.registered__info_link}>
                  accountname@email.com
                </div>
                <button className={styles.registered__info_btn}>
                  <img src={LogoutIcon} alt="LogoutIcon" />
                </button>
              </div>
              <div className={styles.registered__row}>
                <div className={styles.registered__info_id}>ID 1231231231</div>
                <button className={styles.registered__info_btn}>
                  <img src={EditIcon} alt="EditIcon" />
                </button>
              </div>
            </div>
            <div className={styles.registered__stats}>
              <div className={styles.registered__row}>
                <div className={styles.registered__stats_label}>
                  Your HP balance:
                </div>
                <div className={styles.registered__stats_value}>
                  3050
                  <img src={GemIcon} alt="diamond" />
                </div>
              </div>
              <div className={styles.registered__row}>
                <div className={styles.registered__stats_label}>
                  Leaderboard Position:
                </div>
                <div className={styles.registered__stats_value}>12345</div>
              </div>
              <div className={styles.registered__row}>
                <div className={styles.registered__stats_label}>
                  Quests Completed:
                </div>
                <div className={styles.registered__stats_value}>
                  12<span>/50</span>
                </div>
              </div>
            </div>
            <ProgressBar currentStep={12} totalSteps={50} />
            <div className={styles.referrals}>
              <div className={styles.registered__stats_label}>
                Active Referrals:
              </div>
              <div
                className={combineClasses(
                  styles,
                  "registered__stats_label",
                  "registered__stats_label_mob"
                )}
              >
                Referrals:
              </div>
              <div className={styles.registered__stats_value}>
                <div>Unlocks at Lvl3</div>
                <img src={LockIcon} alt="LockIcon" />
              </div>
            </div>
            <div className={styles.registered__description}>
              Complete quests, earn Hero Points, unlock new levels and
              assignments. Advance the Leaderboard to earn exclusive rewards.
            </div>
            <div className={styles.block__btn_wrapper}>
              <div className={styles.block__btn} onClick={scrollToTarget}>
                Check Leaderboard
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default QuestFloatingBlock;
