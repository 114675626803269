import React from "react";

import styles from "./ProgressBar.module.scss";

function ProgressBar({ currentStep, totalSteps }) {
  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <div>
      <div className={styles.bar}>
        <div
          className={styles.bar__current}
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;
