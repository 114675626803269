import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import styles from "./Promo.module.scss";
import playIcon from "../../../../img/playIcon.svg";
import confetiIcon from "../../../../img/confeti.svg";
import solanaIcon from "../../../../img/solana.svg";
import unityIcon from "../../../../img/unity.svg";
import webAppIcon from "../../../../img/webAppLanding.svg";
import telegramIcon from "../../../../img/telegramLanding.svg";
import Header from "../../../Header/Header";
import VideoIcon from "../../../../img/video.png";
import CloseIcon from "../../../../img/close.svg";

function Promo() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isShowModal, setIsShowModal] = useState(false);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setMousePosition({ x: clientX, y: clientY });
  };

  const calcTransform = () => {
    const xOffset = (mousePosition.x / window.innerWidth) * 10;
    const yOffset = (mousePosition.y / window.innerHeight) * 10;
    return `translate(${xOffset}px, ${yOffset}px)`;
  };

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div onMouseMove={handleMouseMove}>
      <Header />
      <div className={styles.promo}>
        <div className="container">
          <div className={styles.hero}>
            <div className={styles.hero__bg}></div>
            <img
              className={styles.hero__confeti}
              src={confetiIcon}
              alt="confetiIcon"
              style={{ transform: calcTransform() }}
            />
            <div className={styles.hero__container}>
              <div className={styles.hero__title}>
                Become a Hero of Web3 Battle Royale
              </div>
              <div className={styles.hero__description}>
                Electrifying mix of battle royale, platformer, and arcade
                action. Compete with 32 players in fast-paced elimination
                rounds. Will you rise to the top? Join now and prove your
                prowess!
              </div>
              <div className={styles.hero__wrapper}>
                <div className={styles.hero__btn_wrapper}>
                  <div className={styles.hero__btn}>Go To Waitlist</div>
                </div>
                <div
                  className={styles.hero__watch}
                  onClick={() => setIsShowModal(true)}
                >
                  <img src={playIcon} alt="playIcon" />
                  <div>Watch trailer</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.available}>
            <div>
              <img
                className="wow animate__animated animate__fadeInUp"
                src={solanaIcon}
                alt="solanaIcon"
              />
            </div>
            <div>
              <img
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.25s"
                src={unityIcon}
                alt="unityIcon"
              />
            </div>
            <div>
              <img
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
                src={webAppIcon}
                alt="webAppIcon"
              />
            </div>
            <div>
              <img
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.75s"
                src={telegramIcon}
                alt="telegramIcon"
              />
            </div>
          </div>
          <div className={styles.about}>
            <div className={styles.about__title}>About Hero Guys</div>
            <div className={styles.about__description}>
              Electrifying mix of battle royale, platformer, and arcade action.
              Compete with 60 players in fast-paced elimination rounds. Will you
              rise to the top? Join now and prove your prowess!
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bg}></div>
      {isShowModal ? (
        <div className={styles.modal}>
          <div className={styles.modal__container}>
            <div
              className={styles.modal__button}
              onClick={() => setIsShowModal(false)}
            >
              <div>Close</div>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <img src={VideoIcon} alt="VideoIcon" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Promo;
