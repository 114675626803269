import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import QuestPage from "./components/QuestPage/QuestPage";
import CurrentQuest from "./components/CurrentQuest/CurrentQuest";
import Landing from "./components/Landing/Landing";
import "animate.css/animate.min.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/quests" element={<QuestPage />} />
        <Route path="/quest" element={<CurrentQuest />} />
      </Routes>
    </Router>
  );
}

export default App;
