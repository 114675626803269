import React, { useEffect, useMemo, useState } from "react";

import styles from "./Main.module.scss";
import ArrowIcon from "../../../../img/arrow.svg";
import TelegramIcon from "../../../../img/telegram.svg";
import DiscordIcon from "../../../../img/discord.svg";
import CloseIcon from "../../../../img/close.svg";
import QuestLevel from "../QuestLevel/QuestLevel";
import ActionStep from "../ActionStep/ActionStep";
import WalletIcon from "../../../../img/wallet.svg";
import CupIcon from "../../../../img/cup_gold.svg";
import GemIcon from "../../../../img/gem.svg";
import CryptoMonkeysIcon from "../../../../img/cryptomonkeys.svg";
import ParticipantsIcon from "../../../../img/participants.svg";
import Header from "../../../Header/Header";
import questTwitterIcon from "../../../../img/twitter.svg";
import InviteIcon from "../../../../img/invite.svg";
import InviteFriendsIcon from "../../../../img/inviteFriends.svg";
import CopyIcon from "../../../../img/copy.svg";
import CreateContentIcon from "../../../../img/content.svg";
import QuizIcon from "../../../../img/quiz.svg";
import { useLocation, useNavigate } from "react-router-dom";

import questEmailIcon from "../../../../img/questEmail.svg";
import questQuestionIcon from "../../../../img/questQuestion.svg";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import ChampionIcon from "../../../../img/champion.svg";

import Champion1Icon from "../../../../img/champion1.svg";
import Champion2Icon from "../../../../img/champion2.svg";
import Champion3Icon from "../../../../img/champion3.svg";

import Team1Icon from "../../../../img/team1.svg";
import Team2Icon from "../../../../img/team2.svg";
import Team3Icon from "../../../../img/team3.svg";

import teamIcon from "../../../../img/team.svg";

const mockData = [
  {
    number: 1,
    text: "Do some action #1",
    isCompleted: true,
  },
  {
    number: 2,
    text: "Do some action #2",
    isCompleted: false,
  },
  {
    number: 3,
    text: "Do some action #3",
    isCompleted: false,
  },
];

const mockDataEmailQuest = [
  {
    number: 1,
    text: "Enter Email",
    isCompleted: null,
  },
  {
    number: 2,
    text: "Validate Email",
    isCompleted: false,
  },
];

function Main() {
  const location = useLocation();
  const navigate = useNavigate();

  const [date, setDate] = useState(null);
  const [heroQuestionStep, setHeroQuestionStep] = useState(1);
  const [selectedOptionHeroQuestion, setSelectedOptionHeroQuestion] =
    useState("Classic Degen");
  const [selectedOptionHeroQuestion2, setSelectedOptionHeroQuestion2] =
    useState("No");
  const [selectedOptionHeroQuestion2_1, setSelectedOptionHeroQuestion2_1] =
    useState("No");
  const [selectedOptionHeroQuestion3, setSelectedOptionHeroQuestion3] =
    useState("Answer #1");
  const [
    selectedOptionHeroQuestionTextValue,
    setSelectedOptionHeroQuestionTextValue,
  ] = useState("");
  const [
    selectedOptionHeroQuestionTextValue2,
    setSelectedOptionHeroQuestionTextValue2,
  ] = useState("");

  const [selectedOptionHeroQuestion4, setSelectedOptionHeroQuestion4] =
    useState("value1");

  const [modalSuccessInfo, setModalSuccessInfo] = useState({
    isOpen: false,
    image: null,
    rewards: null,
  });

  const [stepsCompleteActivities, setStepsCompleteActivities] = useState([
    {
      number: 1,
      text: "Follow @heroguys_io on X",
      isCompleted: null,
    },
    {
      number: 2,
      text: "Add “emoji” to your X ",
      isCompleted: false,
    },
    {
      number: 3,
      text: "Add “text” to your X",
      isCompleted: false,
    },
    {
      number: 4,
      text: "Like Post",
      isCompleted: false,
    },
    {
      number: 5,
      text: "Re-twit Post",
      isCompleted: false,
    },
    {
      number: 6,
      text: "Comment Post",
      isCompleted: false,
    },
  ]);

  const [stepsCompleteDiscord, setStepsCompleteDiscord] = useState([
    {
      number: 1,
      text: "Join Discord",
      isCompleted: null,
    },
    {
      number: 2,
      text: "Verify your Discord Account",
      isCompleted: false,
    },
    {
      number: 3,
      text: "Get the *name role",
      isCompleted: false,
    },
  ]);

  const [stepsTelegramDiscord, setStepsTelegramDiscord] = useState([
    {
      number: 1,
      text: "Join Telegram Channel",
      isCompleted: null,
    },
    {
      number: 2,
      text: "Join Telegram Chat",
      isCompleted: false,
    },
  ]);

  const [stepsWelcomeSurvey, setStepsWelcomeSurvey] = useState([
    {
      number: 1,
      text: "You age?",
      isCompleted: null,
    },
    {
      number: 2,
      text: "What Roles describe you best?",
      isCompleted: false,
    },
    {
      number: 3,
      text: "Played any web3 games?",
      isCompleted: false,
    },
    {
      number: 4,
      text: "Played any web2 games?",
      isCompleted: false,
    },
  ]);

  const [mockDataSelectChampion, setMockDataSelectChampion] = useState([
    {
      number: 1,
      text: "Learn about Tournaments",
      isCompleted: null,
    },
    {
      number: 2,
      text: "Take Quiz",
      isCompleted: false,
    },
    {
      number: 3,
      text: "Select One of 3 champions",
      isCompleted: false,
    },
  ]);

  const [stepsInvite, setStepsInvite] = useState([
    {
      number: 1,
      text: "Learn about Tournaments",
      isCompleted: null,
    },
    {
      number: 2,
      text: "Take Quiz",
      isCompleted: false,
    },
  ]);

  const [stepsCreateContent, setStepsCreateContent] = useState([
    {
      number: 1,
      text: "Post with Hero Guys(MEME) on X",
      isCompleted: null,
    },
    {
      number: 2,
      text: "Make the post",
      isCompleted: false,
    },
    {
      number: 3,
      text: "You can make additional posts and get more points for content about Hero Guys",
      isCompleted: false,
    },
  ]);

  const [stepsLearn, setStepsLearn] = useState([
    {
      number: 1,
      text: "Read About the Game on the Main Page",
      isCompleted: null,
    },
    {
      number: 2,
      text: "Complete Quiz",
      isCompleted: false,
    },
  ]);

  const handleOptionChangeHeroQuestion = (event) => {
    setSelectedOptionHeroQuestion(event.target.value);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        "https://www.heroguys.io/?ref=antimarvel"
      );
    } catch (err) {}
  };

  const inviteLink = useMemo(() => "https://www.heroguys.io/?ref=antimarvel");

  const questType = useMemo(() => {
    return location?.state?.questType;
  }, [location.state]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (modalSuccessInfo.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [modalSuccessInfo.isOpen]);

  return (
    <div className={styles.main}>
      <Header />
      <div className="container">
        {questType === 0 ? (
          <div className={styles.main__container}>
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={questEmailIcon} alt="questEmailIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={1} />
                    </div>
                    <div className={styles.main__info_title}>
                      Join the Waitlist with an Email
                    </div>
                    <div className={styles.main__info_description}>
                      You can boost your place to be first, who gets access to
                      our point drop season.
                    </div>
                  </div>
                </div>
                <div className={styles.main__info_step_title}>
                  Finish all steps
                </div>
                <div className={styles.main__info_list}>
                  {mockDataEmailQuest?.map((item, index) => {
                    return <ActionStep key={index} actionInfo={item} />;
                  })}
                </div>
                <dir className={styles.main__rewards}>
                  <div className={styles.main__rewards_wrapper}>
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0</span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.main__action} ${styles.main__action_quest}`}
              >
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={questEmailIcon} alt="TelegramIcon" />
                  </div>
                  <div className={styles.main__action_title}>Enter Email</div>
                  <div className={styles.main__action_description}>
                    Ipsum horizen arweave bancor polkadot IOTA bancor. Secret
                    solana siacoin dai maker velas. Amp digibyte algorand TRON
                    enjin telcoin terraUSD audius celo serum.
                  </div>
                  <label className={styles.main__action_input}>
                    <div>Enter valid email</div>
                    <input type="text" />
                  </label>
                  <br />
                  <div className={styles.main__action_btn_wrapper}>
                    <div className={styles.main__action_btn}>Action</div>
                  </div>
                </div>
                <div className={styles.main__action_footer}>
                  <div className={styles.main__action_skip}>Skip</div>
                  <div
                    className={styles.main__action_verify}
                    onClick={() =>
                      setModalSuccessInfo({
                        isOpen: true,
                        image: questEmailIcon,
                        rewards: ["+ 100 HP"],
                      })
                    }
                  >
                    Verify
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : questType === "welcome" ? (
          <div
            className={`${styles.main__container} ${styles.main__container_quest1}`}
          >
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={questQuestionIcon} alt="questQuestionIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={1} />
                    </div>
                    <div className={styles.main__info_title}>
                      Complete Welcome Survey
                    </div>
                  </div>
                </div>
                <br />
                <div className={styles.main__info_step_title}>
                  Finish all steps
                </div>
                <div className={styles.main__info_list}>
                  {stepsWelcomeSurvey?.map((item, index) => {
                    return <ActionStep key={index} actionInfo={item} />;
                  })}
                </div>
                <dir
                  className={`${styles.main__rewards} ${styles.main__rewards_2}`}
                >
                  <div
                    className={`${styles.main__rewards_wrapper} ${styles.main__rewards_wrapper_2}`}
                  >
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div>
                    <div className={styles.main__rewards_wrapper}>
                      100
                      <img src={GemIcon} alt="GemIcon" />
                      HP
                    </div>
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0 </span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.main__action} ${styles.main__action_quest}`}
              >
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={questQuestionIcon} alt="questQuestionIcon" />
                  </div>
                  <div className={styles.main__action_title}>
                    Complete Welcome Survey
                  </div>
                  {heroQuestionStep === 1 ? (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "32px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className={styles.calendar__label}>You age? </div>
                      <label className={styles.calendar}>
                        <div className={styles.calendar__date}>
                          {date?.toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </div>
                        <Flatpickr
                          value={date}
                          onChange={(selectedDates) => {
                            setDate(selectedDates[0]);
                          }}
                          options={{ dateFormat: "Y-m-d" }}
                        />
                      </label>
                    </div>
                  ) : null}
                  {heroQuestionStep === 2 ? (
                    <div style={{ width: "100%", marginTop: "32px" }}>
                      <div className={styles.quest__title}>
                        What Roles describe you best? 
                      </div>
                      <div className={styles.quest__subtitle}>
                        Select one answer
                      </div>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="Classic Degen"
                          checked={
                            selectedOptionHeroQuestion === "Classic Degen"
                          }
                          onChange={handleOptionChangeHeroQuestion}
                        />
                        <span className={styles.radio__label}>
                          Classic Degen
                        </span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="Web3 Gamer"
                          checked={selectedOptionHeroQuestion === "Web3 Gamer"}
                          onChange={handleOptionChangeHeroQuestion}
                        />
                        <span className={styles.radio__label}>Web3 Gamer</span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="Web2 Gamer"
                          checked={selectedOptionHeroQuestion === "Web2 Gamer"}
                          onChange={handleOptionChangeHeroQuestion}
                        />
                        <span className={styles.radio__label}>Web2 Gamer</span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="NFT Flipper"
                          checked={selectedOptionHeroQuestion === "NFT Flipper"}
                          onChange={handleOptionChangeHeroQuestion}
                        />
                        <span className={styles.radio__label}>NFT Flipper</span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="Casual Gamer"
                          checked={
                            selectedOptionHeroQuestion === "Casual Gamer"
                          }
                          onChange={handleOptionChangeHeroQuestion}
                        />
                        <span className={styles.radio__label}>
                          Casual Gamer
                        </span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="Web3 Worker"
                          checked={selectedOptionHeroQuestion === "Web3 Worker"}
                          onChange={handleOptionChangeHeroQuestion}
                        />
                        <span className={styles.radio__label}>Web3 Worker</span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="Other"
                          checked={selectedOptionHeroQuestion === "Other"}
                          onChange={handleOptionChangeHeroQuestion}
                        />
                        <span className={styles.radio__label}>Other</span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      {selectedOptionHeroQuestion === "Other" ? (
                        <textarea
                          className={styles.textarea}
                          value={selectedOptionHeroQuestionTextValue}
                          onChange={(e) =>
                            setSelectedOptionHeroQuestionTextValue(
                              e.target.value
                            )
                          }
                        ></textarea>
                      ) : null}
                    </div>
                  ) : null}
                  {heroQuestionStep === 3 ? (
                    <div style={{ width: "100%", marginTop: "32px" }}>
                      <div className={styles.quest__title}>
                        Played any web3 games?
                      </div>
                      <div className={styles.quest__subtitle}>
                        If yes, please mention it
                      </div>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="No"
                          checked={selectedOptionHeroQuestion2 === "No"}
                          onChange={(event) =>
                            setSelectedOptionHeroQuestion2(event.target.value)
                          }
                        />
                        <span className={styles.radio__label}>No</span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="Yes"
                          checked={selectedOptionHeroQuestion2 === "Yes"}
                          onChange={(event) =>
                            setSelectedOptionHeroQuestion2(event.target.value)
                          }
                        />
                        <span className={styles.radio__label}>Yes</span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      {selectedOptionHeroQuestion2 === "Yes" ? (
                        <textarea
                          className={styles.textarea}
                          value={selectedOptionHeroQuestionTextValue2}
                          onChange={(e) =>
                            setSelectedOptionHeroQuestionTextValue2(
                              e.target.value
                            )
                          }
                        ></textarea>
                      ) : null}
                    </div>
                  ) : null}
                  {heroQuestionStep === 4 ? (
                    <div style={{ width: "100%", marginTop: "32px" }}>
                      <div className={styles.quest__title}>
                        Played any web2 games?
                      </div>
                      <div className={styles.quest__subtitle}>
                        If yes, please mention it
                      </div>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="No"
                          checked={selectedOptionHeroQuestion2 === "No"}
                          onChange={(event) =>
                            setSelectedOptionHeroQuestion2_1(event.target.value)
                          }
                        />
                        <span className={styles.radio__label}>No</span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <input
                          type="radio"
                          name="options"
                          value="Yes"
                          checked={selectedOptionHeroQuestion2 === "Yes"}
                          onChange={(event) =>
                            setSelectedOptionHeroQuestion2_1(event.target.value)
                          }
                        />
                        <span className={styles.radio__label}>Yes</span>
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      {selectedOptionHeroQuestion2_1 === "Yes" ? (
                        <textarea
                          className={styles.textarea}
                          value={selectedOptionHeroQuestionTextValue2}
                          onChange={(e) =>
                            setSelectedOptionHeroQuestionTextValue2(
                              e.target.value
                            )
                          }
                        ></textarea>
                      ) : null}
                    </div>
                  ) : null}
                  <br />
                  <div
                    className={styles.main__action_btn_wrapper}
                    onClick={() => {
                      if (heroQuestionStep < 4) {
                        scrollToTop();
                        setHeroQuestionStep((prev) => prev + 1);
                        setStepsWelcomeSurvey((prevSteps) =>
                          prevSteps.map((step) =>
                            step.number === heroQuestionStep
                              ? { ...step, isCompleted: true }
                              : step.number === heroQuestionStep + 1
                              ? { ...step, isCompleted: null }
                              : step.isCompleted === false
                              ? { ...step, isCompleted: false }
                              : step
                          )
                        );
                      } else {
                        setStepsWelcomeSurvey((prevSteps) =>
                          prevSteps.map((step) =>
                            step.number === heroQuestionStep
                              ? { ...step, isCompleted: true }
                              : step.number === heroQuestionStep + 1
                              ? { ...step, isCompleted: null }
                              : step.isCompleted === false
                              ? { ...step, isCompleted: false }
                              : step
                          )
                        );
                        setModalSuccessInfo({
                          isOpen: true,
                          image: questQuestionIcon,
                          rewards: ["+ 100 HP"],
                        });
                      }
                    }}
                  >
                    <div className={styles.main__action_btn}>Action</div>
                  </div>
                </div>
                <div className={styles.main__action_footer}>
                  {/* <div className={styles.main__action_footer_noty}>
                    Step {heroQuestionStep} from 3
                  </div> */}
                  {/* {heroQuestionStep > 1 ? (
                    <div
                      className={styles.main__action_skip}
                      style={{ width: "152px" }}
                      onClick={() => setHeroQuestionStep((prev) => prev - 1)}
                    >
                      Previous
                    </div>
                  ) : null} */}
                  {heroQuestionStep < 3 //   onClick={() => { //   className={styles.main__action_verify} // <div
                    ? //     scrollToTop();
                      //     setHeroQuestionStep((prev) => prev + 1);
                      //   }}
                      // >
                      //   NEXT
                      // </div>
                      // <div className={styles.main__action_btn_wrapper}>
                      //   <div className={styles.main__action_btn}>Action</div>
                      // </div>
                      null
                    : // <div
                      //   className={styles.main__action_btn}
                      //   onClick={() =>
                      //     setModalSuccessInfo({
                      //       isOpen: true,
                      //       image: questQuestionIcon,
                      //       rewards: ["+ 100 HP", "+ 100 HP"],
                      //     })
                      //   }
                      //   style={{ cursor: "pointer", width: "125px" }}
                      // >
                      //   SEND
                      // </div>
                      null}
                </div>
              </div>
            </div>
          </div>
        ) : questType === "champion" ? (
          <div
            className={`${styles.main__container} ${styles.main__container_quest1}`}
          >
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={ChampionIcon} alt="ChampionIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={2} />
                    </div>
                    <div className={styles.main__info_title}>
                      Select a Champion
                    </div>
                    <br />
                    <div className={styles.main__info_step_title}>
                      Finish all steps
                    </div>
                    <div className={styles.main__info_list}>
                      {mockDataSelectChampion?.map((item, index) => {
                        return <ActionStep key={index} actionInfo={item} />;
                      })}
                    </div>
                  </div>
                </div>
                <dir
                  className={`${styles.main__rewards} ${styles.main__rewards_2}`}
                >
                  <div
                    className={`${styles.main__rewards_wrapper} ${styles.main__rewards_wrapper_2}`}
                  >
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div>
                    <div className={styles.main__rewards_wrapper}>
                      +1 Champion
                    </div>
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0 </span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.main__action} ${styles.main__action_quest}`}
              >
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={ChampionIcon} alt="ChampionIcon" />
                  </div>
                  <div className={styles.main__action_title}>
                    Select a Champion
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    className={styles.main__action_text}
                  >
                    {heroQuestionStep === 1 ? (
                      <div className={styles.main__action_text_overflow}>
                        Celo USD arweave serum THETA crypto ICON. Bancor EOS
                        uniswap nexo algorand compound. Tezos tezos IOTA audius
                        flow arweave ren. Decred serum crypto hive telcoin IOTA
                        litecoin aave ox terra. Zcash ankr IOTA celsius TRON
                        eCash. Maker bancor serum serum bancor quant. Amp
                        ethereum enjin polygon eCash ipsum ipsum aave nexo
                        velas. Decred harmony hedera arweave ethereum monero
                        helium enjin. Celsius decred quant dai stacks stacks
                        loopring digibyte polkadot. Decentraland digibyte zcash
                        avalanche chainlink. Polygon enjin stacks binance
                        binance waves terraUSD amp. EOS polkadot stacks
                        BitTorrent siacoin tether elrond. Ren dash polygon
                        polygon velas hive audius maker quant.
                        <br />
                        <br />
                        Loopring ren TRON dogecoin litecoin terra. Horizen
                        kadena WAX hive hive kava arweave filecoin dogecoin. Ox
                        digibyte aave monero chainlink hedera. Tezos EOS
                        compound waves chainlink. EOS uniswap stacks
                        decentraland dogecoin. Kusama ethereum elrond ICON
                        polkadot. ICON stacks horizen ipsum solana litecoin
                        compound. IOTA XRP litecoin uniswap eCash neo compound
                        uniswap. Cardano uniswap telcoin stellar terra IOTA
                        solana ICON polygon compound. Livepeer hedera nexo
                        digibyte litecoin.
                        <br />
                        <br />
                        Audius amp cosmos horizen flow velas. Maker bitcoin
                        waves dai aave bancor audius horizen. Revain TRON amp
                        holo loopring IOTA compound binance. Cardano secret aave
                        audius nexo dash elrond. Maker chainlink vechain quant
                        vechain enjin polkadot. XRP ethereum decred IOTA
                        ethereum elrond algorand stacks compound. Decentraland
                        cardano cardano aave compound maker litecoin flow
                        polymath. Telcoin uniswap dogecoin aave ethereum
                        algorand tether USD. ECash IOTA eCash vechain eCash.
                        IOTA polymath crypto cardano loopring shiba-inu. Dash
                        loopring flow cosmos bitcoin EOS PancakeSwap hedera
                        decred filecoin. THETA dash polkadot nexo BitTorrent
                        gala terraUSD. BitTorrent chiliz USD harmony digibyte.
                        <br />
                        <br />
                        Ethereum ox helium compound stellar. Golem uniswap
                        telcoin celo litecoin TRON velas compound siacoin
                        monero. Klaytn chainlink livepeer kadena quant. Bancor
                        dogecoin kava vechain USD ox polkadot neo. Telcoin ren
                        waves celo chiliz TRON chainlink hive ankr. Bitcoin
                        chiliz dai kava tezos ankr aave algorand stellar.
                        <br />
                        <br />
                        Kava golem kadena celsius digibyte dai WAX decred. Gala
                        holo celsius horizen siacoin flow secret. Algorand serum
                        bitcoin ren digibyte. Terra helium vechain amp uniswap
                        digibyte litecoin kadena. BitTorrent aave dash THETA
                        polkadot cosmos celsius golem digibyte. Tether ethereum
                        crypto TRON audius golem gala aave secret eCash. Decred
                        holo kadena USD harmony binance polygon golem amp.
                        Telcoin celsius cardano kusama ICON polygon secret enjin
                        algorand. Uniswap waves decentraland secret cardano
                        arweave kava horizen ren IOTA. Ethereum THETA tether
                        vechain fantom audius ox. Enjin polymath tether siacoin
                        IOTA siacoin aave.
                      </div>
                    ) : null}
                    {heroQuestionStep === 2 ? (
                      <div style={{ width: "100%" }}>
                        <div className={styles.quest__title}>Question #1</div>
                        <div className={styles.quest__subtitle}>
                          Select one answer
                        </div>
                        <label className={styles.radio}>
                          <input
                            type="radio"
                            name="options"
                            value="Answer #1"
                            checked={
                              selectedOptionHeroQuestion3 === "Answer #1"
                            }
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion3(event.target.value)
                            }
                          />
                          <span className={styles.radio__label}>Answer #1</span>
                          <span className={styles.radio__checkmark}></span>
                        </label>
                        <label className={styles.radio}>
                          <input
                            type="radio"
                            name="options"
                            value="Answer #2"
                            checked={
                              selectedOptionHeroQuestion3 === "Answer #2"
                            }
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion3(event.target.value)
                            }
                          />
                          <span className={styles.radio__label}>Answer #2</span>
                          <span className={styles.radio__checkmark}></span>
                        </label>
                        <label className={styles.radio}>
                          <input
                            type="radio"
                            name="options"
                            value="Answer #3"
                            checked={
                              selectedOptionHeroQuestion3 === "Answer #3"
                            }
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion3(event.target.value)
                            }
                          />
                          <span className={styles.radio__label}>Answer #3</span>
                          <span className={styles.radio__checkmark}></span>
                        </label>
                      </div>
                    ) : null}
                    {heroQuestionStep === 3 ? (
                      <div
                        className={`${styles.champion__wrapper} ${styles.main__action_quest_champion}`}
                      >
                        <label className={styles.radio}>
                          <div
                            className={`${styles.label__champion} ${
                              selectedOptionHeroQuestion4 === "value1"
                                ? styles.label__champion_active
                                : ""
                            } `}
                          >
                            <img src={Champion1Icon} alt="Champion1Icon" />
                            <div>Kitty</div>
                          </div>
                          <input
                            type="radio"
                            name="options"
                            value="value1"
                            checked={selectedOptionHeroQuestion4 === "value1"}
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion4(event.target.value)
                            }
                          />
                          <span className={styles.radio__checkmark}></span>
                        </label>
                        <label className={styles.radio}>
                          <div
                            className={`${styles.label__champion} ${
                              selectedOptionHeroQuestion4 === "value2"
                                ? styles.label__champion_active
                                : ""
                            } `}
                          >
                            <img src={Champion2Icon} alt="Champion1Icon" />
                            <div>Monkey</div>
                          </div>
                          <input
                            type="radio"
                            name="options"
                            value="value2"
                            checked={selectedOptionHeroQuestion4 === "value2"}
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion4(event.target.value)
                            }
                          />
                          <span className={styles.radio__checkmark}></span>
                        </label>
                        <label className={styles.radio}>
                          <div
                            className={`${styles.label__champion} ${
                              selectedOptionHeroQuestion4 === "value3"
                                ? styles.label__champion_active
                                : ""
                            } `}
                          >
                            <img src={Champion3Icon} alt="Champion1Icon" />
                            <div>Pirate Joe</div>
                          </div>
                          <input
                            type="radio"
                            name="options"
                            value="value3"
                            checked={selectedOptionHeroQuestion4 === "value3"}
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion4(event.target.value)
                            }
                          />
                          <span className={styles.radio__checkmark}></span>
                        </label>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`${styles.main__action_footer} ${styles.main__action_footer_center}`}
                >
                  {/* <div className={styles.main__action_footer_noty}>
                    Step {heroQuestionStep} from 3
                  </div> */}
                  {/* {heroQuestionStep > 1 ? (
                    // <div
                    //   className={styles.main__action_skip}
                    //   style={{ width: "152px" }}
                    //   onClick={() => setHeroQuestionStep((prev) => prev - 1)}
                    // >
                    //   Previous
                    // </div>
                    // <div className={styles.main__action_btn_wrapper}>
                    //   <div className={styles.main__action_btn}>Action</div>
                    // </div>
                  ) : null} */}
                  {heroQuestionStep < 3 ? (
                    // <div
                    //   className={styles.main__action_verify}
                    //   onClick={() => {
                    //     scrollToTop();
                    //     setHeroQuestionStep((prev) => prev + 1);
                    //   }}
                    // >
                    //   NEXT
                    // </div>
                    <div
                      className={styles.main__action_btn_wrapper}
                      onClick={() => {
                        scrollToTop();
                        setHeroQuestionStep((prev) => prev + 1);
                        setMockDataSelectChampion((prevSteps) =>
                          prevSteps.map((step) =>
                            step.number === heroQuestionStep
                              ? { ...step, isCompleted: true }
                              : step.number === heroQuestionStep + 1
                              ? { ...step, isCompleted: null }
                              : step.isCompleted === false
                              ? { ...step, isCompleted: false }
                              : step
                          )
                        );
                      }}
                    >
                      <div className={styles.main__action_btn}>Action</div>
                    </div>
                  ) : (
                    <div
                      className={styles.main__action_btn_wrapper}
                      onClick={() => {
                        setMockDataSelectChampion((prevSteps) =>
                          prevSteps.map((step) =>
                            step.number === heroQuestionStep
                              ? { ...step, isCompleted: true }
                              : step.number === heroQuestionStep + 1
                              ? { ...step, isCompleted: null }
                              : step.isCompleted === false
                              ? { ...step, isCompleted: false }
                              : step
                          )
                        );
                        setModalSuccessInfo({
                          isOpen: true,
                          image: ChampionIcon,
                          rewards: ["+1 Champion"],
                        });
                      }}
                    >
                      <div className={styles.main__action_btn}>Action</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : questType === "team" ? (
          <div
            className={`${styles.main__container} ${styles.main__container_quest1}`}
          >
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={teamIcon} alt="teamIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={1} />
                    </div>
                    <div className={styles.main__info_title}>
                      Choose your team
                    </div>
                  </div>
                </div>
                <dir className={styles.main__rewards}>
                  <div className={styles.main__rewards_wrapper}>
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.main__rewards_bonus}>
                  <div className={styles.main__rewards_bonus_title}>
                    + bonus “individual tag”
                  </div>
                  <div className={styles.main__rewards_bonus_wrapper}>
                    <div>
                      <img src={CryptoMonkeysIcon} alt="CryptoMonkeys" />
                      CryptoMonkeys
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          d="M5 0L6.725 2.01221L9.33013 2.5L8.45 5L9.33013 7.5L6.725 7.98779L5 10L3.275 7.98779L0.669873 7.5L1.55 5L0.669873 2.5L3.275 2.01221L5 0Z"
                          fill="white"
                        />
                      </svg>
                      Early-Tester NFT
                    </div>
                  </div>
                </div>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0 </span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.main__action} ${styles.main__action_quest} ${styles.main__action_quest_team}`}
              >
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={teamIcon} alt="teamIcon" />
                  </div>
                  <div className={styles.main__action_title}>
                    Choose your team
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    className={styles.main__action_text}
                  >
                    <div className={styles.champion__wrapper}>
                      <label className={styles.radio}>
                        <div
                          className={`${styles.label__champion} ${
                            styles.label__champion_wide
                          } ${
                            selectedOptionHeroQuestion4 === "value1"
                              ? styles.label__champion_wide_active
                              : ""
                          } `}
                        >
                          <img src={Team1Icon} alt="Team1Icon" />
                          <div>CryptoMonkeys</div>
                        </div>
                        <div className={styles.champion__label}>
                          Team A <br /> (e.g. CryptoMonkeys)
                        </div>
                        <input
                          type="radio"
                          name="options"
                          value="value1"
                          checked={selectedOptionHeroQuestion4 === "value1"}
                          onChange={(event) =>
                            setSelectedOptionHeroQuestion4(event.target.value)
                          }
                        />
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <div
                          className={`${styles.label__champion} ${
                            styles.label__champion_wide
                          } ${
                            selectedOptionHeroQuestion4 === "value2"
                              ? styles.label__champion_wide_active
                              : ""
                          } `}
                        >
                          <img src={Team2Icon} alt="Team2Icon" />
                          <div>BullRunners</div>
                        </div>
                        <div className={styles.champion__label}>
                          Team B <br /> (e.g. BullRunners)
                        </div>
                        <input
                          type="radio"
                          name="options"
                          value="value2"
                          checked={selectedOptionHeroQuestion4 === "value2"}
                          onChange={(event) =>
                            setSelectedOptionHeroQuestion4(event.target.value)
                          }
                        />
                        <span className={styles.radio__checkmark}></span>
                      </label>
                      <label className={styles.radio}>
                        <div
                          className={`${styles.label__champion} ${
                            styles.label__champion_wide
                          } ${
                            selectedOptionHeroQuestion4 === "value3"
                              ? styles.label__champion_wide_active
                              : ""
                          } `}
                        >
                          <img src={Team3Icon} alt="Team3Icon" />
                          <div>BullRunners</div>
                        </div>
                        <div className={styles.champion__label}>
                          Team C <br /> (e.g. Pumpsters)
                        </div>
                        <input
                          type="radio"
                          name="options"
                          value="value3"
                          checked={selectedOptionHeroQuestion4 === "value3"}
                          onChange={(event) =>
                            setSelectedOptionHeroQuestion4(event.target.value)
                          }
                        />
                        <span className={styles.radio__checkmark}></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.main__action_footer} ${styles.main__action_footer_center}`}
                >
                  <div
                    className={styles.main__action_btn}
                    onClick={() =>
                      setModalSuccessInfo({
                        isOpen: true,
                        image: teamIcon,
                        rewards: ["+ 100 HP"],
                      })
                    }
                    style={{ cursor: "pointer", width: "125px" }}
                  >
                    Confirm
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : questType === "x" ? (
          <div className={styles.main__container}>
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={questTwitterIcon} alt="questTwitterIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={1} />
                    </div>
                    <div className={styles.main__info_title}>
                      Complete Activities on X
                    </div>
                  </div>
                  <br />
                </div>
                <div className={styles.main__info_step_title}>
                  Finish all steps
                </div>
                <div className={styles.main__info_list}>
                  {stepsCompleteActivities?.map((item, index) => {
                    return <ActionStep key={index} actionInfo={item} />;
                  })}
                </div>
                <dir className={styles.main__rewards}>
                  <div className={styles.main__rewards_wrapper}>
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0</span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.main__action}>
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={questTwitterIcon} alt="questTwitterIcon" />
                  </div>
                  <div
                    className={styles.main__action_title}
                    style={{ marginBottom: "16px" }}
                  >
                    {heroQuestionStep === 1
                      ? "Follow @heroguys_io on X"
                      : heroQuestionStep === 2
                      ? "Add “emoji” to your X"
                      : heroQuestionStep === 3
                      ? "Add “text” to your X"
                      : heroQuestionStep === 4
                      ? "Like Post"
                      : heroQuestionStep === 5
                      ? "Re-twit Post"
                      : "Comment Post"}
                  </div>
                  <div className={styles.main__action_btn_wrapper}>
                    <div className={styles.main__action_btn}>Action</div>
                  </div>
                </div>
                <div className={styles.main__action_footer}>
                  <div
                    className={styles.main__action_verify}
                    onClick={() => {
                      scrollToTop();
                      setHeroQuestionStep((prev) => prev + 1);
                      setStepsCompleteActivities((prevSteps) =>
                        prevSteps.map((step) =>
                          step.number === heroQuestionStep
                            ? { ...step, isCompleted: true }
                            : step.number === heroQuestionStep + 1
                            ? { ...step, isCompleted: null }
                            : step.isCompleted === false
                            ? { ...step, isCompleted: false }
                            : step
                        )
                      );
                    }}
                  >
                    Verify1
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : questType === "discord" ? (
          <div className={styles.main__container}>
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={DiscordIcon} alt="DiscordIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={1} />
                    </div>
                    <div className={styles.main__info_title}>
                      Join the Community on Discord
                    </div>
                    <br />
                  </div>
                </div>
                <div className={styles.main__info_step_title}>
                  Finish all steps
                </div>
                <div className={styles.main__info_list}>
                  {stepsCompleteDiscord?.map((item, index) => {
                    return <ActionStep key={index} actionInfo={item} />;
                  })}
                </div>
                <dir className={styles.main__rewards}>
                  <div className={styles.main__rewards_wrapper}>
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0</span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.main__action}>
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={DiscordIcon} alt="DiscordIcon" />
                  </div>
                  <div
                    className={styles.main__action_title}
                    style={{ marginBottom: "16px" }}
                  >
                    {heroQuestionStep === 1
                      ? "Join Discord"
                      : heroQuestionStep === 2
                      ? "Verify your Discord Account"
                      : "Get the *name role"}
                  </div>
                  <div className={styles.main__action_btn_wrapper}>
                    <div className={styles.main__action_btn}>Action</div>
                  </div>
                </div>
                <div className={styles.main__action_footer}>
                  <div
                    className={styles.main__action_verify}
                    onClick={() => {
                      scrollToTop();
                      setHeroQuestionStep((prev) => prev + 1);
                      setStepsCompleteDiscord((prevSteps) =>
                        prevSteps.map((step) =>
                          step.number === heroQuestionStep
                            ? { ...step, isCompleted: true }
                            : step.number === heroQuestionStep + 1
                            ? { ...step, isCompleted: null }
                            : step.isCompleted === false
                            ? { ...step, isCompleted: false }
                            : step
                        )
                      );
                    }}
                  >
                    Verify
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : questType === "telegram" ? (
          <div className={styles.main__container}>
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={TelegramIcon} alt="TelegramIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={1} />
                    </div>
                    <div className={styles.main__info_title}>
                      Join Telegram Community
                    </div>
                    <br />
                  </div>
                </div>
                <div className={styles.main__info_step_title}>
                  Finish all steps
                </div>
                <div className={styles.main__info_list}>
                  {stepsTelegramDiscord?.map((item, index) => {
                    return <ActionStep key={index} actionInfo={item} />;
                  })}
                </div>
                <dir className={styles.main__rewards}>
                  <div className={styles.main__rewards_wrapper}>
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0</span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.main__action}>
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={TelegramIcon} alt="TelegramIcon" />
                  </div>
                  <div
                    className={styles.main__action_title}
                    style={{ marginBottom: "16px" }}
                  >
                    {heroQuestionStep === 1
                      ? "Join Telegram Channel"
                      : "Join Telegram Chat"}
                  </div>
                  <div className={styles.main__action_btn_wrapper}>
                    <div className={styles.main__action_btn}>Action</div>
                  </div>
                </div>
                <div className={styles.main__action_footer}>
                  <div
                    className={styles.main__action_verify}
                    onClick={() => {
                      scrollToTop();
                      setHeroQuestionStep((prev) => prev + 1);
                      setStepsTelegramDiscord((prevSteps) =>
                        prevSteps.map((step) =>
                          step.number === heroQuestionStep
                            ? { ...step, isCompleted: true }
                            : step.number === heroQuestionStep + 1
                            ? { ...step, isCompleted: null }
                            : step.isCompleted === false
                            ? { ...step, isCompleted: false }
                            : step
                        )
                      );
                    }}
                  >
                    Verify
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : questType === "wallet" ? (
          <div className={styles.main__container}>
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={WalletIcon} alt="WalletIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={1} />
                    </div>
                    <div className={styles.main__info_title}>
                      Connect your Wallet
                    </div>
                    <br />
                  </div>
                </div>
                <dir className={styles.main__rewards}>
                  <div className={styles.main__rewards_wrapper}>
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0</span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.main__action}>
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={WalletIcon} alt="WalletIcon" />
                  </div>
                  <div
                    className={styles.main__action_title}
                    style={{ marginBottom: "16px" }}
                  >
                    Connect your Wallet
                  </div>
                  <div className={styles.main__action_btn_wrapper}>
                    <div className={styles.main__action_btn}>Action</div>
                  </div>
                </div>
                <div className={styles.main__action_footer}>
                  <div
                    className={styles.main__action_verify}
                    onClick={() => {
                      scrollToTop();
                      setHeroQuestionStep((prev) => prev + 1);
                    }}
                  >
                    Verify
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : questType === "invitation" ? (
          <div
            className={`${styles.main__container} ${styles.main__container_quest1}`}
          >
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={InviteIcon} alt="InviteIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={2} />
                    </div>
                    <div className={styles.main__info_title}>
                      Earn Invitation to Tournament
                    </div>
                    <br />
                    <div className={styles.main__info_step_title}>
                      Finish all steps
                    </div>
                    <div className={styles.main__info_list}>
                      {stepsInvite?.map((item, index) => {
                        return <ActionStep key={index} actionInfo={item} />;
                      })}
                    </div>
                  </div>
                </div>
                <dir
                  className={`${styles.main__rewards} ${styles.main__rewards_2}`}
                >
                  <div
                    className={`${styles.main__rewards_wrapper} ${styles.main__rewards_wrapper_2}`}
                  >
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0 </span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.main__action} ${styles.main__action_quest}`}
              >
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={InviteIcon} alt="InviteIcon" />
                  </div>
                  <div className={styles.main__action_title}>
                    {heroQuestionStep === 1
                      ? "Learn about Tournaments"
                      : "Take Quiz"}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    className={styles.main__action_text}
                  >
                    {heroQuestionStep === 1 ? (
                      <div className={styles.main__action_text_overflow}>
                        Celo USD arweave serum THETA crypto ICON. Bancor EOS
                        uniswap nexo algorand compound. Tezos tezos IOTA audius
                        flow arweave ren. Decred serum crypto hive telcoin IOTA
                        litecoin aave ox terra. Zcash ankr IOTA celsius TRON
                        eCash. Maker bancor serum serum bancor quant. Amp
                        ethereum enjin polygon eCash ipsum ipsum aave nexo
                        velas. Decred harmony hedera arweave ethereum monero
                        helium enjin. Celsius decred quant dai stacks stacks
                        loopring digibyte polkadot. Decentraland digibyte zcash
                        avalanche chainlink. Polygon enjin stacks binance
                        binance waves terraUSD amp. EOS polkadot stacks
                        BitTorrent siacoin tether elrond. Ren dash polygon
                        polygon velas hive audius maker quant.
                        <br />
                        <br />
                        Loopring ren TRON dogecoin litecoin terra. Horizen
                        kadena WAX hive hive kava arweave filecoin dogecoin. Ox
                        digibyte aave monero chainlink hedera. Tezos EOS
                        compound waves chainlink. EOS uniswap stacks
                        decentraland dogecoin. Kusama ethereum elrond ICON
                        polkadot. ICON stacks horizen ipsum solana litecoin
                        compound. IOTA XRP litecoin uniswap eCash neo compound
                        uniswap. Cardano uniswap telcoin stellar terra IOTA
                        solana ICON polygon compound. Livepeer hedera nexo
                        digibyte litecoin.
                        <br />
                        <br />
                        Audius amp cosmos horizen flow velas. Maker bitcoin
                        waves dai aave bancor audius horizen. Revain TRON amp
                        holo loopring IOTA compound binance. Cardano secret aave
                        audius nexo dash elrond. Maker chainlink vechain quant
                        vechain enjin polkadot. XRP ethereum decred IOTA
                        ethereum elrond algorand stacks compound. Decentraland
                        cardano cardano aave compound maker litecoin flow
                        polymath. Telcoin uniswap dogecoin aave ethereum
                        algorand tether USD. ECash IOTA eCash vechain eCash.
                        IOTA polymath crypto cardano loopring shiba-inu. Dash
                        loopring flow cosmos bitcoin EOS PancakeSwap hedera
                        decred filecoin. THETA dash polkadot nexo BitTorrent
                        gala terraUSD. BitTorrent chiliz USD harmony digibyte.
                        <br />
                        <br />
                        Ethereum ox helium compound stellar. Golem uniswap
                        telcoin celo litecoin TRON velas compound siacoin
                        monero. Klaytn chainlink livepeer kadena quant. Bancor
                        dogecoin kava vechain USD ox polkadot neo. Telcoin ren
                        waves celo chiliz TRON chainlink hive ankr. Bitcoin
                        chiliz dai kava tezos ankr aave algorand stellar.
                        <br />
                        <br />
                        Kava golem kadena celsius digibyte dai WAX decred. Gala
                        holo celsius horizen siacoin flow secret. Algorand serum
                        bitcoin ren digibyte. Terra helium vechain amp uniswap
                        digibyte litecoin kadena. BitTorrent aave dash THETA
                        polkadot cosmos celsius golem digibyte. Tether ethereum
                        crypto TRON audius golem gala aave secret eCash. Decred
                        holo kadena USD harmony binance polygon golem amp.
                        Telcoin celsius cardano kusama ICON polygon secret enjin
                        algorand. Uniswap waves decentraland secret cardano
                        arweave kava horizen ren IOTA. Ethereum THETA tether
                        vechain fantom audius ox. Enjin polymath tether siacoin
                        IOTA siacoin aave.
                      </div>
                    ) : null}
                    {heroQuestionStep === 2 ? (
                      <div style={{ width: "100%" }}>
                        <div className={styles.quest__title}>Question #1</div>
                        <div className={styles.quest__subtitle}>
                          Select one answer
                        </div>
                        <label className={styles.radio}>
                          <input
                            type="radio"
                            name="options"
                            value="Answer #1"
                            checked={
                              selectedOptionHeroQuestion3 === "Answer #1"
                            }
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion3(event.target.value)
                            }
                          />
                          <span className={styles.radio__label}>Answer #1</span>
                          <span className={styles.radio__checkmark}></span>
                        </label>
                        <label className={styles.radio}>
                          <input
                            type="radio"
                            name="options"
                            value="Answer #2"
                            checked={
                              selectedOptionHeroQuestion3 === "Answer #2"
                            }
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion3(event.target.value)
                            }
                          />
                          <span className={styles.radio__label}>Answer #2</span>
                          <span className={styles.radio__checkmark}></span>
                        </label>
                        <label className={styles.radio}>
                          <input
                            type="radio"
                            name="options"
                            value="Answer #3"
                            checked={
                              selectedOptionHeroQuestion3 === "Answer #3"
                            }
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion3(event.target.value)
                            }
                          />
                          <span className={styles.radio__label}>Answer #3</span>
                          <span className={styles.radio__checkmark}></span>
                        </label>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`${styles.main__action_footer} ${styles.main__action_footer_center}`}
                >
                  {heroQuestionStep < 2 ? (
                    <div
                      className={styles.main__action_btn_wrapper}
                      onClick={() => {
                        scrollToTop();
                        setHeroQuestionStep((prev) => prev + 1);
                        setStepsInvite((prevSteps) =>
                          prevSteps.map((step) =>
                            step.number === heroQuestionStep
                              ? { ...step, isCompleted: true }
                              : step.number === heroQuestionStep + 1
                              ? { ...step, isCompleted: null }
                              : step.isCompleted === false
                              ? { ...step, isCompleted: false }
                              : step
                          )
                        );
                      }}
                    >
                      <div className={styles.main__action_btn}>Action</div>
                    </div>
                  ) : (
                    <div
                      className={styles.main__action_btn_wrapper}
                      onClick={() => {
                        setStepsInvite((prevSteps) =>
                          prevSteps.map((step) =>
                            step.number === heroQuestionStep
                              ? { ...step, isCompleted: true }
                              : step.number === heroQuestionStep + 1
                              ? { ...step, isCompleted: null }
                              : step.isCompleted === false
                              ? { ...step, isCompleted: false }
                              : step
                          )
                        );
                        setModalSuccessInfo({
                          isOpen: true,
                          image: ChampionIcon,
                          rewards: ["+100 Gem"],
                        });
                      }}
                    >
                      <div className={styles.main__action_btn}>Action</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : questType === "invite" ? (
          <div className={styles.main__container}>
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={InviteFriendsIcon} alt="InviteFriendsIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={3} />
                    </div>
                    <div className={styles.main__info_title}>
                      Invite friends <span>(Unlimited)</span>
                    </div>
                  </div>
                </div>
                <dir className={styles.main__rewards}>
                  <div className={styles.main__rewards_wrapper}>
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0</span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.main__action}>
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={InviteFriendsIcon} alt="InviteFriendsIcon" />
                  </div>
                  <div
                    className={styles.main__action_title}
                    style={{ marginBottom: "16px" }}
                  >
                    Invite your friends
                  </div>
                  <div className={styles.main__action_invite}>
                    <div className={styles.main__action_invite_title}>
                      Copy and sent your refferal link to your freinds
                    </div>
                    <div className={styles.main__action_invite_input}>
                      <input type="text" value={inviteLink} readOnly />
                      <button onClick={() => copyToClipboard()}>
                        <img src={CopyIcon} alt="CopyIcon" />
                      </button>
                    </div>
                    <div className={styles.main__action_invite_footer}>
                      Referral counted if a user completes all quests on the
                      Community side
                    </div>
                  </div>
                  <div className={styles.main__action_btn_wrapper}>
                    <div className={styles.main__action_btn}>Action</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : questType === "content" ? (
          <div className={styles.main__container}>
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={CreateContentIcon} alt="CreateContentIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={3} />
                    </div>
                    <div className={styles.main__info_title}>
                      Create Content
                    </div>
                    <br />
                  </div>
                </div>
                <div className={styles.main__info_step_title}>
                  Finish all steps
                </div>
                <div className={styles.main__info_list}>
                  {stepsCreateContent?.map((item, index) => {
                    return <ActionStep key={index} actionInfo={item} />;
                  })}
                </div>
                <dir className={styles.main__rewards}>
                  <div className={styles.main__rewards_wrapper}>
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0</span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.main__action}>
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={CreateContentIcon} alt="CreateContentIcon" />
                  </div>
                  <div
                    className={styles.main__action_title}
                    style={{ marginBottom: "16px" }}
                  >
                    {heroQuestionStep === 1
                      ? "Post with Hero Guys(MEME) on X"
                      : heroQuestionStep === 2
                      ? "Make the post why you are bullish on Hero Guys on X"
                      : "You can make additional posts and get more points for content about Hero Guys"}
                  </div>
                  <div
                    className={styles.main__action_btn_wrapper}
                    onClick={() => {
                      scrollToTop();
                      setHeroQuestionStep((prev) => prev + 1);
                      setStepsCreateContent((prevSteps) =>
                        prevSteps.map((step) =>
                          step.number === heroQuestionStep
                            ? { ...step, isCompleted: true }
                            : step.number === heroQuestionStep + 1
                            ? { ...step, isCompleted: null }
                            : step.isCompleted === false
                            ? { ...step, isCompleted: false }
                            : step
                        )
                      );
                    }}
                  >
                    <div className={styles.main__action_btn}>Action</div>
                  </div>
                </div>
                {/* <div className={styles.main__action_footer}>
                  <div
                    className={styles.main__action_verify}
                    onClick={() => {
                      scrollToTop();
                      setHeroQuestionStep((prev) => prev + 1);
                    }}
                  >
                    Verify
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ) : questType === "learn" ? (
          <div
            className={`${styles.main__container} ${styles.main__container_quest1}`}
          >
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={QuizIcon} alt="QuizIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={3} />
                    </div>
                    <div className={styles.main__info_title}>
                      Learn & Answer Quiz 
                    </div>
                    <br />
                    <div className={styles.main__info_step_title}>
                      Finish all steps
                    </div>
                    <div className={styles.main__info_list}>
                      {stepsLearn?.map((item, index) => {
                        return <ActionStep key={index} actionInfo={item} />;
                      })}
                    </div>
                  </div>
                </div>
                <dir
                  className={`${styles.main__rewards} ${styles.main__rewards_2}`}
                >
                  <div
                    className={`${styles.main__rewards_wrapper} ${styles.main__rewards_wrapper_2}`}
                  >
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    100
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>0 </span> / 0
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.main__action} ${styles.main__action_quest}`}
              >
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={QuizIcon} alt="QuizIcon" />
                  </div>
                  <div className={styles.main__action_title}>
                    Post something funny with Hero Guys(MEME) on X
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    className={styles.main__action_text}
                  >
                    {heroQuestionStep === 1 ? (
                      <div className={styles.main__action_text_overflow}>
                        Celo USD arweave serum THETA crypto ICON. Bancor EOS
                        uniswap nexo algorand compound. Tezos tezos IOTA audius
                        flow arweave ren. Decred serum crypto hive telcoin IOTA
                        litecoin aave ox terra. Zcash ankr IOTA celsius TRON
                        eCash. Maker bancor serum serum bancor quant. Amp
                        ethereum enjin polygon eCash ipsum ipsum aave nexo
                        velas. Decred harmony hedera arweave ethereum monero
                        helium enjin. Celsius decred quant dai stacks stacks
                        loopring digibyte polkadot. Decentraland digibyte zcash
                        avalanche chainlink. Polygon enjin stacks binance
                        binance waves terraUSD amp. EOS polkadot stacks
                        BitTorrent siacoin tether elrond. Ren dash polygon
                        polygon velas hive audius maker quant.
                        <br />
                        <br />
                        Loopring ren TRON dogecoin litecoin terra. Horizen
                        kadena WAX hive hive kava arweave filecoin dogecoin. Ox
                        digibyte aave monero chainlink hedera. Tezos EOS
                        compound waves chainlink. EOS uniswap stacks
                        decentraland dogecoin. Kusama ethereum elrond ICON
                        polkadot. ICON stacks horizen ipsum solana litecoin
                        compound. IOTA XRP litecoin uniswap eCash neo compound
                        uniswap. Cardano uniswap telcoin stellar terra IOTA
                        solana ICON polygon compound. Livepeer hedera nexo
                        digibyte litecoin.
                        <br />
                        <br />
                        Audius amp cosmos horizen flow velas. Maker bitcoin
                        waves dai aave bancor audius horizen. Revain TRON amp
                        holo loopring IOTA compound binance. Cardano secret aave
                        audius nexo dash elrond. Maker chainlink vechain quant
                        vechain enjin polkadot. XRP ethereum decred IOTA
                        ethereum elrond algorand stacks compound. Decentraland
                        cardano cardano aave compound maker litecoin flow
                        polymath. Telcoin uniswap dogecoin aave ethereum
                        algorand tether USD. ECash IOTA eCash vechain eCash.
                        IOTA polymath crypto cardano loopring shiba-inu. Dash
                        loopring flow cosmos bitcoin EOS PancakeSwap hedera
                        decred filecoin. THETA dash polkadot nexo BitTorrent
                        gala terraUSD. BitTorrent chiliz USD harmony digibyte.
                        <br />
                        <br />
                        Ethereum ox helium compound stellar. Golem uniswap
                        telcoin celo litecoin TRON velas compound siacoin
                        monero. Klaytn chainlink livepeer kadena quant. Bancor
                        dogecoin kava vechain USD ox polkadot neo. Telcoin ren
                        waves celo chiliz TRON chainlink hive ankr. Bitcoin
                        chiliz dai kava tezos ankr aave algorand stellar.
                        <br />
                        <br />
                        Kava golem kadena celsius digibyte dai WAX decred. Gala
                        holo celsius horizen siacoin flow secret. Algorand serum
                        bitcoin ren digibyte. Terra helium vechain amp uniswap
                        digibyte litecoin kadena. BitTorrent aave dash THETA
                        polkadot cosmos celsius golem digibyte. Tether ethereum
                        crypto TRON audius golem gala aave secret eCash. Decred
                        holo kadena USD harmony binance polygon golem amp.
                        Telcoin celsius cardano kusama ICON polygon secret enjin
                        algorand. Uniswap waves decentraland secret cardano
                        arweave kava horizen ren IOTA. Ethereum THETA tether
                        vechain fantom audius ox. Enjin polymath tether siacoin
                        IOTA siacoin aave.
                      </div>
                    ) : null}
                    {heroQuestionStep === 2 ? (
                      <div style={{ width: "100%" }}>
                        <div className={styles.quest__title}>Question #1</div>
                        <div className={styles.quest__subtitle}>
                          Select one answer
                        </div>
                        <label className={styles.radio}>
                          <input
                            type="radio"
                            name="options"
                            value="Answer #1"
                            checked={
                              selectedOptionHeroQuestion3 === "Answer #1"
                            }
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion3(event.target.value)
                            }
                          />
                          <span className={styles.radio__label}>Answer #1</span>
                          <span className={styles.radio__checkmark}></span>
                        </label>
                        <label className={styles.radio}>
                          <input
                            type="radio"
                            name="options"
                            value="Answer #2"
                            checked={
                              selectedOptionHeroQuestion3 === "Answer #2"
                            }
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion3(event.target.value)
                            }
                          />
                          <span className={styles.radio__label}>Answer #2</span>
                          <span className={styles.radio__checkmark}></span>
                        </label>
                        <label className={styles.radio}>
                          <input
                            type="radio"
                            name="options"
                            value="Answer #3"
                            checked={
                              selectedOptionHeroQuestion3 === "Answer #3"
                            }
                            onChange={(event) =>
                              setSelectedOptionHeroQuestion3(event.target.value)
                            }
                          />
                          <span className={styles.radio__label}>Answer #3</span>
                          <span className={styles.radio__checkmark}></span>
                        </label>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`${styles.main__action_footer} ${styles.main__action_footer_center}`}
                >
                  {heroQuestionStep < 2 ? (
                    <div
                      className={styles.main__action_btn_wrapper}
                      onClick={() => {
                        scrollToTop();
                        setHeroQuestionStep((prev) => prev + 1);
                        setStepsLearn((prevSteps) =>
                          prevSteps.map((step) =>
                            step.number === heroQuestionStep
                              ? { ...step, isCompleted: true }
                              : step.number === heroQuestionStep + 1
                              ? { ...step, isCompleted: null }
                              : step.isCompleted === false
                              ? { ...step, isCompleted: false }
                              : step
                          )
                        );
                      }}
                    >
                      <div className={styles.main__action_btn}>Action</div>
                    </div>
                  ) : (
                    <div
                      className={styles.main__action_btn_wrapper}
                      onClick={() => {
                        setStepsLearn((prevSteps) =>
                          prevSteps.map((step) =>
                            step.number === heroQuestionStep
                              ? { ...step, isCompleted: true }
                              : step.number === heroQuestionStep + 1
                              ? { ...step, isCompleted: null }
                              : step.isCompleted === false
                              ? { ...step, isCompleted: false }
                              : step
                          )
                        );
                        setModalSuccessInfo({
                          isOpen: true,
                          image: ChampionIcon,
                          rewards: ["+100 Gem"],
                        });
                      }}
                    >
                      <div className={styles.main__action_btn}>Action</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.main__container}>
            <div
              className={styles.main__back_wrapper}
              onClick={() => navigate("/quests")}
            >
              <div className={styles.main__back}>
                <img src={ArrowIcon} alt="ArrowIcon" />
                Back
              </div>
            </div>
            <div className={styles.main__wrapper}>
              <div className={styles.main__info}>
                <div className={styles.main__info_header}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={TelegramIcon} alt="TelegramIcon" />
                  </div>
                  <div>
                    <div className={styles.main__info_gap}>
                      <QuestLevel level={2} />
                    </div>
                    <div className={styles.main__info_title}>
                      Quest name Quest name Quest name
                    </div>
                    <div className={styles.main__info_description}>
                      You can boost your place to be first, who gets access to
                      our point drop season.
                    </div>
                  </div>
                </div>
                <div className={styles.main__info_step_title}>
                  Finish all steps
                </div>
                <div className={styles.main__info_list}>
                  {mockData?.map((item, index) => {
                    return <ActionStep key={index} actionInfo={item} />;
                  })}
                </div>
                <dir className={styles.main__rewards}>
                  <div className={styles.main__rewards_wrapper}>
                    <img src={CupIcon} alt="CupIcon" />
                    <div>Rewards</div>
                  </div>
                  <div className={styles.main__rewards_wrapper}>
                    340
                    <img src={GemIcon} alt="GemIcon" />
                    HP
                  </div>
                </dir>
                <div className={styles.participants}>
                  <div className={styles.participants__title}>Participants</div>
                  <div className={styles.participants__wrapper}>
                    <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                    <div className={styles.participants__amount}>
                      <span>2 345</span> / 10 000
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.main__action}>
                <div
                  className={styles.main__action_close}
                  onClick={() => navigate("/quests")}
                >
                  Close
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <div className={styles.main__action_info}>
                  <div
                    className={`${styles.main__tg} wow animate__animated animate__tada`}
                  >
                    <img src={TelegramIcon} alt="TelegramIcon" />
                  </div>
                  <div className={styles.main__action_title}>
                    Do some action #2
                  </div>
                  <div className={styles.main__action_description}>
                    Ipsum horizen arweave bancor polkadot IOTA bancor. Secret
                    solana siacoin dai maker velas. Amp digibyte algorand TRON
                    enjin telcoin terraUSD audius celo serum.
                  </div>
                  <div className={styles.main__action_btn_wrapper}>
                    <div className={styles.main__action_btn}>Action</div>
                  </div>
                </div>
                <div className={styles.main__action_footer}>
                  <div className={styles.main__action_skip}>Skip</div>
                  <div className={styles.main__action_verify}>Verify</div>
                </div>
              </div>
            </div>
          </div>
        )}
        {modalSuccessInfo?.isOpen ? (
          <div className={styles.modal}>
            <div className={styles.modal__block}>
              <div
                className={styles.main__action_close}
                onClick={() =>
                  setModalSuccessInfo({
                    isOpen: false,
                    image: null,
                    rewards: null,
                  })
                }
              >
                Close
                <img src={CloseIcon} alt="CloseIcon" />
              </div>
              <img
                className={styles.modal__block_image}
                src={modalSuccessInfo?.image}
                alt="quests"
              />
              <div className={styles.modal__block_title}>
                Quest is completed!
              </div>
              <div>
                <div className={styles.modal__block_label}>
                  You got Rewards:
                </div>
                {modalSuccessInfo?.rewards?.map((item, index) => {
                  return (
                    <div key={index} className={styles.modal__block_reward}>
                      {item}
                    </div>
                  );
                })}
                <div
                  className={styles.main__back_wrapper}
                  onClick={() =>
                    setModalSuccessInfo({
                      isOpen: false,
                      image: null,
                      rewards: null,
                    })
                  }
                >
                  <div className={styles.main__back}>Check your account</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Main;
