import React from "react";

import styles from "./LeaderboardRow.module.scss";
import CopyIcon from "../../../../img/copy.svg";
import GemIcon from "../../../../img/gem.svg";
import CrownIcon from "../../../../img/crown.svg";
import combineClasses from "../../../../helpers/combineClasses";
import LeaderBoardYouIcon from "../../../../img/leaderboard_you.svg";
import LeaderBoardTopIcon from "../../../../img/leaderborad_top.svg";
import LeaderBoardRankIcon from "../../../../img/leaderboard_rank.svg";

function LeaderboardRow({ rowData, index }) {
  const { id, invites, isYou, points, rank } = rowData;
  return (
    <div className={combineClasses(styles, "row", isYou ? "row__me" : "")}>
      <div
        className={styles.row__rank}
        style={{ background: rank <= 3 ? "#9C6713" : "#621489" }}
      >
        <div className={styles.row__rank_number}>{rank}</div>
        {isYou ? (
          <img src={LeaderBoardYouIcon} alt="LeaderBoardYouIcon" />
        ) : rank <= 3 ? (
          <img src={LeaderBoardTopIcon} alt="LeaderBoardTopIcon" />
        ) : (
          <img src={LeaderBoardRankIcon} alt="LeaderBoardRankIcon" />
        )}
      </div>
      <div
        className={styles.row__player}
        style={{ background: rank <= 3 ? "#9C6713" : "#621489" }}
      >
        <button>
          <img src={CopyIcon} alt="CopyIcon" />
        </button>
        <div>{id}</div>
        {isYou ? (
          <div className={styles.row__badge}>
            <img src={CrownIcon} alt="CrownIcon" />
            <div>YOU</div>
          </div>
        ) : null}
      </div>
      <div
        className={styles.row__invites}
        style={{ background: rank <= 3 ? "#9C6713" : "#621489" }}
      >
        {invites}
      </div>
      <div
        className={styles.row__hero}
        style={{ background: rank <= 3 ? "#9C6713" : "#621489" }}
      >
        {points}
        <img src={GemIcon} alt="GemIcon" />
      </div>
    </div>
  );
}

export default LeaderboardRow;
