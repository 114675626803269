import React, { useState } from "react";

import styles from "./Leaderboard.module.scss";
import LeaderBoardBadge from "../LeaderBoardBadge/LeaderBoardBadge";
import LeaderboardRow from "../LeaderboardRow/LeaderboardRow";
import CupIcon from "../../../../img/leaderboardBg.svg";

const mockData = [
  {
    rank: 256,
    id: "123456",
    invites: 10,
    points: 678999,
    isYou: true,
  },
  {
    rank: 1,
    id: "Theamazingkitten",
    invites: 1000,
    points: 678999,
    isYou: false,
  },
  {
    rank: 2,
    id: "Marveious",
    invites: 200,
    points: 678999,
    isYou: false,
  },
  {
    rank: 3,
    id: "Thor",
    invites: 100,
    points: 678999,
    isYou: false,
  },
  {
    rank: 4,
    id: "123456",
    invites: 80,
    points: 678999,
    isYou: false,
  },
  {
    rank: 5,
    id: "123456",
    invites: 70,
    points: 678999,
    isYou: false,
  },
  {
    rank: 6,
    id: "123456",
    invites: 65,
    points: 678999,
    isYou: false,
  },
  {
    rank: 7,
    id: "123456",
    invites: 60,
    points: 678999,
    isYou: false,
  },
  {
    rank: 8,
    id: "123456",
    invites: 54,
    points: 678999,
    isYou: false,
  },
  {
    rank: 9,
    id: "123456",
    invites: 50,
    points: 678999,
    isYou: false,
  },
  {
    rank: 10,
    id: "123456",
    invites: 45,
    points: 678999,
    isYou: false,
  },
];

function LeaderBoard() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setMousePosition({ x: clientX, y: clientY });
  };

  const calcTransform = () => {
    const xOffset = (mousePosition.x / window.innerWidth) * 30;
    const yOffset = (mousePosition.y / window.innerHeight) * 30;
    return `translate(${xOffset}px, ${yOffset}px)`;
  };

  return (
    <div
      className={styles.leaderboard}
      onMouseMove={handleMouseMove}
      id="leaderboard"
    >
      <div className="container">
        <div className={styles.leaderboard__container}>
          <img
            className={styles.leaderboard__bg}
            style={{ transform: calcTransform() }}
            src={CupIcon}
            alt="CupIcon"
          />
          <div className={styles.leaderboard__title}>Leaderboard</div>
          <div className={styles.leaderboard__header}>
            <LeaderBoardBadge topNumber={3} bonus={50000} />
            <LeaderBoardBadge topNumber={10} bonus={25000} />
            <LeaderBoardBadge topNumber={100} bonus={1000} />
            <LeaderBoardBadge topNumber={1000} bonus={1000} />
          </div>
          <div className={styles.leaderboard__table}>
            <div className={styles.leaderboard__table_header}>
              <div className={styles.leaderboard__table_rank}>Rank</div>
              <div className={styles.leaderboard__table_player}>Player</div>
              <div className={styles.leaderboard__table_invites}>Invites</div>
              <div className={styles.leaderboard__table_hero}>HERO Points</div>
            </div>
            {mockData?.map((item, index) => {
              return (
                <LeaderboardRow key={index} rowData={item} index={index} />
              );
            })}
            <div className={styles.leaderboard__table_load}>Load More</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaderBoard;
