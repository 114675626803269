import React from "react";

import styles from "./Rooms.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import map1Image from "../../../../img/images/Maps/map1.png";
import map2Image from "../../../../img/images/Maps/map2.png";

import boost1Icon from "../../../../img/images/Maps/boost1.png";
import boost2Icon from "../../../../img/images/Maps/boost2.png";

import multiverseIcon from "../../../../img/images/Maps/multiverses.png";
import { Autoplay } from "swiper/modules";

function Rooms() {
  return (
    <div className={styles.rooms}>
      <div className="container">
        <div className={styles.rooms__title}>Over 45 Random Maps</div>
      </div>
      <div className={styles.rooms__slider1}>
        <Swiper
          slidesPerView={"auto"}
          // centeredSlides={true}
          loop={true}
          spaceBetween={24}
          className={styles.swiper}
          speed={1000}
          // autoplay={{
          //   delay: 700,
          //   disableOnInteraction: false,
          //   pauseOnMouseEnter: true,
          //   reverseDirection: true,
          // }}
          // modules={[Autoplay]}
          // onInit={(swiper) =>
          //   setTimeout(() => {
          //     swiper.autoplay?.start();
          //     swiper.slideTo(2, 0);
          //   }, 1000)
          // }
        >
          <SwiperSlide>
            <img src={map1Image} alt="map1Image" />
            <div className={styles.maps__title}>Level 2 - Sprint Forest</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={map2Image} alt="map1Image" />
            <div className={styles.maps__title}>Level 2 - Sprint Forest</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={map1Image} alt="map1Image" />
            <div className={styles.maps__title}>Level 2 - Sprint Forest</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={map1Image} alt="map1Image" />
            <div className={styles.maps__title}>Level 2 - Sprint Forest</div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="container">
        <div className={styles.rooms__title}>Over 100 Obstacles & Boosters</div>
      </div>
      <div className={styles.rooms__slider2}>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={24}
          loop={true}
          centeredSlides={true}
          onSlideChange={() => null}
          onSwiper={(swiper) => console.log(swiper)}
          className={styles.swiper}
          speed={1000}
          // autoplay={{
          //   delay: 700,
          //   disableOnInteraction: false,
          //   pauseOnMouseEnter: true,
          //   reverseDirection: false,
          // }}
          // modules={[Autoplay]}
          // onInit={(swiper) =>
          //   setTimeout(() => {
          //     swiper.autoplay?.start();
          //     swiper.slideTo(5, 0);
          //   }, 1000)
          // }
        >
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost1Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boost2Icon} alt="map1Image" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="container">
        <div className={styles.rooms__title}>9 Unique Multiverses</div>
      </div>
      <div className={styles.rooms__slider3}>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={24}
          centeredSlides={true}
          loop={true}
          onSlideChange={() => null}
          onSwiper={(swiper) => console.log(swiper)}
          className={styles.swiper}
          speed={1000}
          // autoplay={{
          //   delay: 900,
          //   disableOnInteraction: false,
          //   pauseOnMouseEnter: true,
          //   reverseDirection: true,
          // }}
          // modules={[Autoplay]}
          // onInit={(swiper) =>
          //   setTimeout(() => {
          //     swiper.slideTo(2, 0);
          //     swiper.autoplay?.start();
          //   }, 1000)
          // }
        >
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={multiverseIcon} alt="multiverseIcon" />
            <div className={styles.maps__title}>Grid</div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Rooms;
