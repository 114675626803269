import React, { useMemo } from "react";

import styles from "./LeaderBoardBadge.module.scss";
import Top3Icon from "../../../../img/top3.svg";
import Top10Icon from "../../../../img/top10.svg";
import Top100Icon from "../../../../img/top100.svg";
import Top1000Icon from "../../../../img/top1000.svg";

function LeaderBoardBadge({ topNumber, bonus }) {
  const iconMap = useMemo(() => {
    return {
      3: Top3Icon,
      10: Top10Icon,
      100: Top100Icon,
      1000: Top1000Icon,
    };
  }, []);

  const IconComponent = iconMap[topNumber] || null;

  return (
    <div
      className={styles.badge}
      style={{
        borderColor:
          topNumber === 3
            ? "#FC0"
            : topNumber === 10
            ? "#D500F9"
            : topNumber === 100
            ? "#4AF8FF"
            : "#0F0",
      }}
    >
      {IconComponent && (
        <img src={IconComponent} alt={`Top ${topNumber} Icon`} />
      )}
      <div>
        <div className={styles.badge__title}>Top {topNumber}</div>
        <div className={styles.badge__text}>Get bonus</div>
        <div className={styles.badge__text}>{bonus} HP</div>
      </div>
    </div>
  );
}

export default LeaderBoardBadge;
