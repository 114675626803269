import React from "react";
import Promo from "./components/Promo/Promo";
import QuestSection from "./components/QuestSection/QuestSection";
import LeaderBoard from "./components/Leaderboard/Leaderboard";
import Faq from "./components/Faq/Faq";
import Footer from "../Footer/Footer";

// import styles from "./QuestPage.module.scss";

function QuestPage() {
  return (
    <div>
      <Promo />
      <QuestSection />
      <LeaderBoard />
      <Faq />
      <Footer />
    </div>
  );
}

export default QuestPage;
