import React, { useState } from "react";

import styles from "./QuestSection.module.scss";
import QuestCard from "../QuestCard/QuestCard";
import QuestLevel from "../QuestLevel/QuestLevel";
import combineClasses from "../../../../helpers/combineClasses";
import QuestFloatingBlock from "../QuestFloatingBlock/QuestFloatingBlock";

import questEmailIcon from "../../../../img/questEmail.svg";
import questTwitterIcon from "../../../../img/twitter.svg";
import questDiscordIcon from "../../../../img/discord.svg";
import questQuestionIcon from "../../../../img/question.svg";
import questWalletIcon from "../../../../img/wallet.svg";
import ChampionIcon from "../../../../img/champion.svg";
import TeamIcon from "../../../../img/team.svg";
import InviteIcon from "../../../../img/invite.svg";
import InviteFriendsIcon from "../../../../img/inviteFriends.svg";
import CreateContentIcon from "../../../../img/content.svg";
import QuizIcon from "../../../../img/quiz.svg";

const mockData = [
  {
    name: "Join the Waitlist with an Email",
    points: 100,
    progressSteps: 2,
    progressCompleted: 1,
    image: questEmailIcon,
  },
  {
    name: "Complete Activities on X",
    points: 150,
    progressSteps: 6,
    progressCompleted: 1,
    image: questTwitterIcon,
  },
  {
    name: "Join the Community on Discord",
    points: 100,
    progressSteps: 3,
    progressCompleted: 0,
    image: questDiscordIcon,
  },
  {
    name: "Join Telegram Community",
    points: 100,
    progressSteps: 2,
    progressCompleted: 0,
  },
  {
    name: "Complete Welcome Survey",
    points: 100,
    progressSteps: 4,
    progressCompleted: 0,
    image: questQuestionIcon,
  },
  {
    name: "Connect your Wallet",
    points: 100,
    progressSteps: 1,
    progressCompleted: 0,
    image: questWalletIcon,
  },
];

const mockData2 = [
  {
    name: "Select a Champion",
    points: "1 champion",
    progressSteps: 3,
    progressCompleted: 0,
    image: ChampionIcon,
    isNeedGem: false,
  },
  {
    name: "Choose your team",
    points: 150,
    progressSteps: 1,
    progressCompleted: 0,
    image: TeamIcon,
  },
  {
    name: "Earn Invitation to Tournament",
    points: 100,
    progressSteps: 2,
    progressCompleted: 0,
    image: InviteIcon,
  },
];

const mockData3 = [
  {
    name: "Invite friends (Unlimited)",
    points: 100,
    progressSteps: 1,
    progressCompleted: 0,
    image: InviteFriendsIcon,
  },
  {
    name: "Create Content (UGC)",
    points: 100,
    progressSteps: 3,
    progressCompleted: 0,
    image: CreateContentIcon,
  },
  {
    name: "Learn & Answer Quiz",
    points: 100,
    progressSteps: 2,
    progressCompleted: 0,
    image: QuizIcon,
  },
];

function QuestSection() {
  const [isRegistered, setIsRegistered] = useState(false);

  return (
    <div
      className={combineClasses(
        styles,
        "quest",
        !isRegistered ? "quest__registered" : ""
      )}
    >
      <div className="container">
        <div className={styles.quest__wrapper}>
          <QuestFloatingBlock isRegistered={isRegistered} />
          <div>
            <div className={styles.quest__info}>
              <div className={styles.quest__title}>Quests level 1</div>
              <QuestLevel status={"unlocked"} coins={1200} />
              <div className={styles.quest__row}>
                {mockData.map((item, index) => {
                  return <QuestCard key={index} item={item} index={index} />;
                })}
              </div>
            </div>
            <div className={styles.quest__info}>
              <div className={styles.quest__title}>Quests level 2</div>
              <QuestLevel status={"locked"} coins={2900} />
              <div className={styles.quest__row}>
                {mockData2.map((item, index) => {
                  return (
                    <QuestCard
                      key={index}
                      item={item}
                      color={"purple"}
                      index={item.name}
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles.quest__info}>
              <div className={styles.quest__title}>Quests level 3</div>
              <QuestLevel status={"locked"} coins={2900} />
              <div className={styles.quest__row}>
                {mockData3.map((item, index) => {
                  return (
                    <QuestCard
                      key={index}
                      item={item}
                      color={"pink"}
                      index={item.name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestSection;
