import React, { useState } from "react";

import styles from "./FaqRow.module.scss";

import PlusIcon from "../../../../img/plus.svg";
import MinusIcon from "../../../../img/minus.svg";
import combineClasses from "../../../../helpers/combineClasses";

function FaqRow({ info }) {
  const { question, text } = info;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.row} onClick={() => setIsOpen((prev) => !prev)}>
      <div className={styles.row__header}>
        <div className={styles.row__question}>{question}</div>
        {!isOpen ? (
          <div className={styles.row__btn}>
            <img src={PlusIcon} alt="PlusIcon" />
          </div>
        ) : null}
        {isOpen ? (
          <div className={styles.row__btn_close}>
            <img src={MinusIcon} alt="MinusIcon" />
          </div>
        ) : null}
      </div>
      <div
        className={combineClasses(
          styles,
          "row__description",
          isOpen ? "row__description_open" : ""
        )}
      >
        <div className={styles.row__text}>{text}</div>
        {/* <div className={styles.row__btn_close}>
          <img src={MinusIcon} alt="MinusIcon" />
        </div> */}
      </div>
    </div>
  );
}

export default FaqRow;
