import React from "react";

import styles from "./QuestLevel.module.scss";
import FlashIcon from "../../../../img/flash.svg";
import FlashIcon2 from "../../../../img/flash2.svg";
import FlashIcon3 from "../../../../img/flash3.svg";

function QuestLevel({ level }) {
  return (
    <div
      className={`${styles.questLevel} ${
        level === 2
          ? styles.questLevel_2
          : level === 3
          ? styles.questLevel_3
          : ""
      }`}
    >
      <img
        src={level === 2 ? FlashIcon2 : level === 3 ? FlashIcon3 : FlashIcon}
        alt="FlashIcon"
      />
      Quests level {level}
    </div>
  );
}

export default QuestLevel;
